import React, { useState, useEffect } from 'react';

import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";

import { Link } from "react-router-dom";
import { Grid, Row, Panel, Loader, Stack, List, Tag } from 'rsuite';

import { useSelector } from "react-redux";
import {RootState} from "store";


import ShareableTaskDetailsModal from 'components/tasks/ShareableTaskDetailsModal';
import TimeRemaining from 'components/elements/TimeRemaining';
import { displayCentAmountInDollars, handleViewPublicHandle } from 'utils/utils';


const TaskBrowser = () => {
  const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
  const [takeTaskSolutionModalOpen, setTakeTaskSolutionModalOpen] = useState(false);

  const header: any = useSelector((state: RootState) => state.header);
  const { data: latestTasks, trigger: refreshLatestasks } = useSWRMutation<any>(`/tasks/latest/?q=${header.searchQuery}`, fetcher);

  useEffect(() => {
    refreshLatestasks()
  }, [refreshLatestasks, header.searchQuery]);

  function handleTakeTask(taskId: any) {
    setSelectedTaskId(taskId)
    setTakeTaskSolutionModalOpen(true)
  }

  function handleTakeTaskClose() {
    setTakeTaskSolutionModalOpen(false)
    refreshLatestasks()
  }

  const LatestTaskListItem = ({ task, onClick }: { task: any; onClick: () => void }) => {
    const { title, posted_date, reward_in_cents, zipply_fee_in_cents, due_date } = task;

    return (
      <List.Item>
        <Row onClick={onClick} style={{ cursor: 'pointer' }}><strong>{title}</strong></Row>
        <Row>
          <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
          <Stack.Item style={{fontSize: "14px"}}>{new Date(posted_date).toLocaleDateString()}</Stack.Item>
          <Stack.Item style={{fontSize: "14px"}}><div style={{ cursor: 'pointer' }} onClick={() => handleViewPublicHandle(task.tasker.username)}>{task.tasker.display_name}</div></Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
                <Stack.Item><small><TimeRemaining endDate={new Date(due_date)}/></small></Stack.Item>
                <Stack.Item><Tag style={{width: "40px", textAlign: "right"}} color="blue">{displayCentAmountInDollars(reward_in_cents - zipply_fee_in_cents)}</Tag></Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Row>
    </List.Item>
    );
  };   

  return (
    <div>
      <Grid fluid>
        <Row style={{ margin: '20px' }}>
          <Panel header={<Link to="/tasks" className="nonStyledLink">Task Browser</Link>}>
            {latestTasks && latestTasks.count === 0 && <p>No tasks available</p>}
            {latestTasks && latestTasks.count > 0 && <>
              <ul>
                {latestTasks.results.map((task: any, index: number) => (
                  <LatestTaskListItem task={task} onClick={() => handleTakeTask(task.id)}/>
                ))}
              </ul>
              <ShareableTaskDetailsModal taskId={selectedTaskId} openTaskList={latestTasks.results} open={takeTaskSolutionModalOpen} close={handleTakeTaskClose} />
            </>}
            {!latestTasks && <Loader size="md" content="Fetching tasks..." />}
          </Panel>
        </Row>
      </Grid>
    </div>
  );
};

export default TaskBrowser;
