import React, { useState, useEffect } from 'react';

import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";

import { Link } from "react-router-dom";
import { Grid, Row, Col, Panel, Loader, Tag, List, Stack, Whisper, Tooltip, Badge, Avatar } from 'rsuite';

import InvitedIcon from '@rsuite/icons/legacy/Peoples';
import SubmittedIcon from '@rsuite/icons/legacy/Envelope';
import InvitationRequestIcon from '@rsuite/icons/legacy/Bell';
import MessageIcon from '@rsuite/icons/Message';
import InfoIcon from '@rsuite/icons/InfoOutline';
import { FaExclamationCircle } from 'react-icons/fa'

import { useSelector } from "react-redux";
import { RootState } from "store";

import TaskModal from 'components/tasks/TaskModal';
import SolvedTaskModal from 'components/tasks/SolvedTaskModal';
import CanceledTaskModal from 'components/tasks/CanceledTaskModal';
import SolverSolutionModal from 'components/solutions/SolverSolutionModal';
import TaskerSolutionsModal from 'components/solutions/TaskerSolutionsModal';
import TimeRemaining from 'components/elements/TimeRemaining';
import { displayCentAmountInDollars, mapTaskStatus, mapSolutionStatus, handleViewPublicHandle } from 'utils/utils';
import SolutionDates from 'components/elements/SolutionDates';
import ShareableTaskDetailsModal from 'components/tasks/ShareableTaskDetailsModal';

type Props = {
  isBodyRefresh: boolean
  acknowledgeBodyRefresh: () => void
};

const Dashboard = ({ isBodyRefresh, acknowledgeBodyRefresh }: Props) => {
  const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
  const [takeTaskModalOpen, setTakeTaskModalOpen] = useState(false);
  const [solverSolutionModalOpen, setSolverSolutionModalOpen] = useState(false);
  const [taskerSolutionsModalOpen, setTaskerSolutionsModalOpen] = useState(false);

  const [solvedTaskModalOpen, setSolvedTaskModalOpen] = useState(false);
  const [canceledTaskModalOpen, setCanceledTaskModalOpen] = useState(false);

  const { data: topSolvers } = useSWR<any>('/solvers/top/', fetcher);
  const { data: topTaskers } = useSWR<any>('/taskers/top/', fetcher);

  const header: any = useSelector((state: RootState) => state.header);
  const { data: latestTasks, trigger: refreshLatestTasks } = useSWRMutation<any>(`/tasks/latest/forme/?q=${header.searchQuery}`, fetcher);
  const { data: activeTasks, trigger: refreshActiveTasks } = useSWRMutation<any>('/tasks/mine/active/', fetcher);
  const { data: tasksSolving, trigger: refreshTasksSolving } = useSWRMutation<any>('/tasks/solving/active/', fetcher);

  useEffect(() => {
    refreshTasks()
    acknowledgeBodyRefresh()
  }, [refreshLatestTasks, refreshActiveTasks, refreshTasksSolving, isBodyRefresh]);

  useEffect(() => {
    refreshLatestTasks()
  }, [header.searchQuery]);

  function refreshTasks() {
    refreshLatestTasks();
    refreshActiveTasks();
    refreshTasksSolving();
  }

  function handleTakeTask(taskId: any) {
    setSelectedTaskId(taskId)
    setTakeTaskModalOpen(true)
  }

  function handleTakeTaskClose() {
    setTakeTaskModalOpen(false)
    refreshTasks()
  }

  function handleSolverSolution(taskId: any) {
    setSelectedTaskId(taskId)
    setSolverSolutionModalOpen(true)
  }

  function handleSolverSolutionClose() {
    setSolverSolutionModalOpen(false)
    refreshTasks()
  }

  function handleTaskerSolutions(taskId: any) {
    setSelectedTaskId(taskId)
    setTaskerSolutionsModalOpen(true)
  }

  function handleTaskerSolutionsClose() {
    setTaskerSolutionsModalOpen(false)
    refreshTasks()
  }

  function handleSolvedTask(taskId: any) {
    setSelectedTaskId(taskId)
    setSolvedTaskModalOpen(true)
  }

  function handleCanceledTask(taskId: any) {
    setSelectedTaskId(taskId)
    setCanceledTaskModalOpen(true)
    refreshActiveTasks()
  }

  function handleCanceledTaskClose() {
    setCanceledTaskModalOpen(false)
  }

  function handleSolvedTaskClose() {
    setSolvedTaskModalOpen(false)
    refreshActiveTasks()
  }

  function hasSufficientTopUsers() {
    return topSolvers && topSolvers.count >= 10
  }

  const TaskSolvingListItem = ({ task, handleOnClick }: { task: any; handleOnClick: () => void }) => {
    const { title, my_solution, current_status, posted_date, reward_in_cents, zipply_fee_in_cents, due_date } = task;

    const displayStatus: any = mapSolutionStatus(my_solution.current_status)

    return (
      <List.Item>
        <Row onClick={handleOnClick} style={{ cursor: 'pointer' }}><strong>{title}</strong></Row>
        <Row>
          <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
            <Stack.Item style={{ fontSize: "12px" }}>
              <SolutionDates taskPostedDate={posted_date} solution={task.my_solution} responsive={true} short={true} />
            </Stack.Item>
            <Stack.Item>
              <div style={{ fontSize: "12px" }}>
                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Unread questions from solvers</Tooltip>} >
                  {task.unread_message_count > 0 ? <Badge><MessageIcon /></Badge> : <MessageIcon />}
                </Whisper> {task.unread_message_count}
              </div>
            </Stack.Item>
            <Stack.Item style={{ fontSize: "14px" }}><div style={{ cursor: 'pointer' }} onClick={() => handleViewPublicHandle(task.tasker.username)}>{task.tasker.display_name}</div></Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
                <Stack.Item style={{ cursor: 'pointer' }} >
                  {my_solution.can_provide_feedback ?
                    <Whisper onClick={handleOnClick} placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Please provide timely feedback to tasker!</Tooltip>}>
                      <Badge content={<FaExclamationCircle style={{ color: 'white', fontSize: '10px', marginTop: "-2px" }} />}>
                        <Tag color={displayStatus.color} style={{ width: "100px", textAlign: "center" }}>{displayStatus.text}</Tag>
                      </Badge>
                    </Whisper>
                    :
                    <Tag onClick={handleOnClick} color={displayStatus.color} style={{ width: "100px", textAlign: "center" }}>{displayStatus.text}</Tag>
                  }
                </Stack.Item>
                <Stack.Item style={{ width: "80px" }}>
                  {current_status === 'SOLVED' ?
                    <small>Solved</small>
                    :
                    <>{current_status === 'CANCELED' ?
                      <small>Canceled</small>
                      :
                      <small><TimeRemaining endDate={new Date(due_date)} /></small>
                    }</>
                  }
                </Stack.Item>
                <Stack.Item><Tag style={{ width: "40px", textAlign: "right" }} color="blue">{displayCentAmountInDollars(reward_in_cents - zipply_fee_in_cents)}</Tag></Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Row>
      </List.Item>
    );
  };

  const ActiveTaskListItem = ({ task, handleOnClick }: { task: any; handleOnClick: () => void }) => {
    const { title, current_status, posted_date, reward_in_cents, due_date } = task;

    const displayStatus: any = mapTaskStatus(current_status)

    return (
      <List.Item onClick={handleOnClick} style={{ cursor: 'pointer' }}>
        <Row><strong>{title}</strong></Row>
        <Row>
          <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
            <Stack.Item style={{ fontSize: "14px" }}>{new Date(posted_date).toLocaleDateString()}</Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end" style={{ fontSize: "12px" }}>
                <Stack.Item>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Number solvers who request to participate</Tooltip>}>
                    <InvitationRequestIcon />
                  </Whisper> {task.invitation_request_count}
                </Stack.Item>
                <Stack.Item>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Number of invited solvers</Tooltip>}>
                    <InvitedIcon />
                  </Whisper> {task.invited_count}
                </Stack.Item>
                <Stack.Item>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Number of solutions submitted</Tooltip>}>
                    <SubmittedIcon />
                  </Whisper> {task.submitted_count}
                </Stack.Item>
                <Stack.Item>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Unread questions from solvers</Tooltip>}>
                    {task.unread_message_count > 0 ? <Badge><MessageIcon /></Badge> : <MessageIcon />}
                  </Whisper> {task.unread_message_count}
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
                <Stack.Item>
                  {task.can_provide_feedback ?
                    <Whisper onClick={handleOnClick} placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Please provide timely feedback to solver!</Tooltip>}>
                      <Badge content={<FaExclamationCircle style={{ color: 'white', fontSize: '10px', marginTop: "-2px" }} />}>
                        <Tag color={displayStatus.color} style={{ width: "100px", textAlign: "center" }}>{displayStatus.text}</Tag>
                      </Badge>
                    </Whisper>
                    :
                    <Tag onClick={handleOnClick} color={displayStatus.color} style={{ width: "100px", textAlign: "center" }}>{displayStatus.text}</Tag>
                  }
                </Stack.Item>
                <Stack.Item style={{ width: "80px" }}><small><TimeRemaining endDate={new Date(due_date)} /></small></Stack.Item>
                <Stack.Item><Tag style={{ width: "40px", textAlign: "right" }} color="blue">{displayCentAmountInDollars(reward_in_cents)}</Tag></Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Row>
      </List.Item>
    );
  };

  const LatestTaskListItem = ({ task, handleOnClick }: { task: any; handleOnClick: () => void }) => {
    const { title, posted_date, reward_in_cents, zipply_fee_in_cents, due_date } = task;

    return (
      <List.Item>
        <Row onClick={handleOnClick} style={{ cursor: 'pointer' }}><strong>{title}</strong></Row>
        <Row>
          <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
            <Stack.Item style={{ fontSize: "14px" }}>{new Date(posted_date).toLocaleDateString()}</Stack.Item>
            <Stack.Item style={{ fontSize: "14px" }}><div style={{ cursor: 'pointer' }} onClick={() => handleViewPublicHandle(task.tasker.username)}>{task.tasker.display_name}</div></Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
                <Stack.Item><small><TimeRemaining endDate={new Date(due_date)} /></small></Stack.Item>
                <Stack.Item><Tag style={{ width: "40px", textAlign: "right" }} color="blue">{displayCentAmountInDollars(reward_in_cents - zipply_fee_in_cents)}</Tag></Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Row>
      </List.Item>
    );
  };

  const UserListItem = ({ index, user }: { index: number, user: any }) => {
    const { display_name, username, tasks_solved } = user;

    return (
      <List.Item onClick={() => handleViewPublicHandle(username)} style={{ cursor: 'pointer' }}>
        <Stack justifyContent="space-between" spacing="10px" alignItems="flex-start">
          <Stack.Item><Avatar circle src={user.image_url} /></Stack.Item>
          <Stack.Item>{display_name}</Stack.Item>
          <Stack.Item>{tasks_solved}</Stack.Item>
        </Stack>
      </List.Item>
    );
  };

  return (
    <div>
      <Grid fluid>
        <Row style={{ margin: '20px' }}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Panel className="my-active-tasks" header={
              <Stack justifyContent='space-between'>
                <Stack.Item>
                  <Link to="/my-tasks" className="nonStyledLink">My Active Tasks</Link>
                </Stack.Item>
                <Stack.Item>
                  <Whisper placement="left" controlId="control-id-hover" trigger="hover" speaker={
                    <Tooltip>Amount shown is the full amount the tasker paid for.</Tooltip>
                  }>
                    <InfoIcon />
                  </Whisper>
                </Stack.Item>
              </Stack>
            } bordered style={{ width: '100%' }}>
              {activeTasks && activeTasks.count === 0 && <p>No active tasks available</p>}
              {activeTasks && activeTasks.count > 0 && <>
                <ul>
                  {activeTasks.results.map((task: any, index: number) => {
                    if (task.current_status === 'POSTED')
                      return <ActiveTaskListItem task={task} handleOnClick={() => handleTaskerSolutions(task.id)} />
                    else if (task.current_status === 'CANCELED')
                      return <ActiveTaskListItem task={task} handleOnClick={() => handleCanceledTask(task.id)} />
                    else
                      return <ActiveTaskListItem task={task} handleOnClick={() => handleSolvedTask(task.id)} />
                  }
                  )}
                </ul>
                <TaskerSolutionsModal taskId={selectedTaskId} open={taskerSolutionsModalOpen} close={handleTaskerSolutionsClose} />
                <SolvedTaskModal taskId={selectedTaskId} open={solvedTaskModalOpen} close={handleSolvedTaskClose} />
                <CanceledTaskModal taskId={selectedTaskId} open={canceledTaskModalOpen} close={handleCanceledTaskClose} />
              </>}
              {!activeTasks && <Loader size="md" content="Fetching tasks..." />}
            </Panel>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12}>
            <Panel className="my-solutions" header={
              <Stack justifyContent='space-between'>
                <Stack.Item>
                  <Link to="/my-solutions" className="nonStyledLink">My Solutions</Link>
                </Stack.Item>
                <Stack.Item>
                  <Whisper placement="left" controlId="control-id-hover" trigger="hover" speaker={
                    <Tooltip>Amount shown is full amount minus fees the solver will get paid.</Tooltip>
                  }>
                    <InfoIcon />
                  </Whisper>
                </Stack.Item>
              </Stack>
            } bordered style={{ width: '100%' }}>
              {tasksSolving && tasksSolving.count === 0 && <p>No active solutions available</p>}
              {tasksSolving && tasksSolving.count > 0 && <>
                <ul>
                  {tasksSolving.results.map((task: any, index: number) => (
                    <TaskSolvingListItem task={task} handleOnClick={() => handleSolverSolution(task.id)} />
                  ))}
                </ul>
                <SolverSolutionModal taskId={selectedTaskId} open={solverSolutionModalOpen} close={handleSolverSolutionClose} />
              </>}
              {!tasksSolving && <Loader size="md" content="Fetching tasks..." />}
            </Panel>
          </Col>
        </Row>

        <Row style={{ margin: '20px' }}>
          {hasSufficientTopUsers() &&
          <Col xsHidden smHidden mdHidden lgHidden xl={12}>
            <Panel bordered style={{ width: '100%' }} className="topusers">
              <Row>
                <Col>
                  <p><strong>Top Solvers</strong></p>
                  {topSolvers && topSolvers.count === 0 && <p>No solvers available</p>}
                  {topSolvers && topSolvers.count > 0 &&
                    <ul>
                      {topSolvers.results.map((solver: any, index: number) => (
                        <UserListItem index={index + 1} user={solver} />
                      ))}
                    </ul>
                  }
                  {!topSolvers && <Loader size="md" content="Fetching solvers..." />}
                </Col>
                <Col>
                  <p><strong className="top-users">Top Taskers</strong></p>
                  {topTaskers && topTaskers.count === 0 && <p>No taskers available</p>}
                  {topTaskers && topTaskers.count > 0 &&
                    <ul>
                      {topTaskers.results.map((tasker: any, index: number) => (
                        <UserListItem index={index + 1} user={tasker} />
                      ))}
                    </ul>
                  }
                  {!topTaskers && <Loader size="md" content="Fetching taskers..." />}
                </Col>
              </Row>
            </Panel>
          </Col>
          }
          <Col xs={24} sm={24} md={24} lg={24} xl={hasSufficientTopUsers() ? 12 : 24}>
            <Panel className="latest-tasks" header={
              <Stack justifyContent='space-between'>
                <Stack.Item>
                  <Link to="/tasks" className="nonStyledLink">Latest Tasks</Link>
                </Stack.Item>
                <Stack.Item>
                  <Whisper placement="left" controlId="control-id-hover" trigger="hover" speaker={
                    <Tooltip>Amount shown is full amount minus fees the solver will get paid.</Tooltip>
                  }>
                    <InfoIcon />
                  </Whisper>
                </Stack.Item>
              </Stack>
            } bordered style={{ width: '100%' }}>
              {latestTasks && latestTasks.count === 0 && <p>No solvable tasks available</p>}
              {latestTasks && latestTasks.count > 0 && <>
                <ul>
                  {latestTasks.results.map((task: any, index: number) => (
                    <LatestTaskListItem task={task} handleOnClick={() => handleTakeTask(task.id)} />
                  ))}
                </ul>
                <ShareableTaskDetailsModal taskId={selectedTaskId} openTaskList={latestTasks.results} open={takeTaskModalOpen} close={handleTakeTaskClose} />
              </>}
              {!latestTasks && <Loader size="md" content="Fetching tasks..." />}
            </Panel>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Dashboard;
