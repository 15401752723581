import React, { useState } from 'react';

import useSWR from 'swr'
import { fetcher } from "utils/axios";
import { useSelector } from "react-redux";
import { RootState } from "store";

import { Link } from "react-router-dom";
import { Grid, Row, Panel, Loader, Stack, List, Tag, Col, Avatar } from 'rsuite';

import PublicHeader from 'components/workspace/PublicHeader';
import PublicFooter from 'components/workspace/PublicFooter';
import TimeRemaining from 'components/elements/TimeRemaining';
import { displayCentAmountInDollars } from 'utils/utils';
import ShareableTaskDetailsModal from 'components/tasks/ShareableTaskDetailsModal';


interface Props {
  signIn?: boolean
  signUp?: boolean
  resetPassword?: boolean
}

export default function Home({ signIn, signUp, resetPassword }: Props) {
  const [viewShareableTaskDetailsModalOpen, setViewShareableTaskDetailsModalOpen] = useState<boolean>(false);
  const [taskId, setCurrentTaskId] = useState(null);

  const { data: topSolvers } = useSWR<any>('/solvers/top/', fetcher);
  const { data: topTaskers } = useSWR<any>('/taskers/top/', fetcher);

  const header: any = useSelector((state: RootState) => state.header);
  const { data: latestTasks, mutate } = useSWR<any>(`/tasks/?q=${header.searchQuery}`, fetcher, {
    onSuccess: (data) => {
      setOpenTaskList(data.results.filter((task: any) => task.current_status === 'POSTED' && task.my_solution === null))
    }
  });

  const [openTaskList, setOpenTaskList] = useState<any[]>([]);

  function handleViewTaskDetails(taskId: any) {
    setCurrentTaskId(taskId)
    setViewShareableTaskDetailsModalOpen(true)
  }

  function handleClose() {
    setViewShareableTaskDetailsModalOpen(false)
    mutate()
  }

  const TaskListItem = ({ task, onClick }: { task: any; onClick: () => void }) => {
    const { title, current_status, posted_date, reward_in_cents, zipply_fee_in_cents, due_date } = task;

    return (
      <List.Item onClick={onClick} style={{ cursor: 'pointer' }}>
        <Row style={{ fontWeight: current_status === 'SOLVED' ? 200 : 600 }}>{title}</Row>
        <Row>
          <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
            <Stack.Item>{new Date(posted_date).toLocaleDateString()}</Stack.Item>
            <Stack.Item>
              <Stack justifyContent="space-between" spacing="10px" alignItems="flex-end">
                {current_status === 'SOLVED' ?
                  <Stack.Item><small>Solved</small></Stack.Item>
                  :
                  <Stack.Item><small><TimeRemaining endDate={new Date(due_date)} /></small></Stack.Item>
                }
                <Stack.Item><Tag style={{ width: "40px", textAlign: "right" }} color="blue">{displayCentAmountInDollars(reward_in_cents - zipply_fee_in_cents)}</Tag></Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Row>
      </List.Item>
    );
  };

  const UserListItem = ({ index, user }: { index: number, user: any }) => {
    const { display_name, username, tasks_solved } = user;

    return (
      <List.Item onClick={() => handleViewPublicHandle(user.username)} style={{ cursor: 'pointer' }}>
        <Stack justifyContent="space-between" spacing="10px" alignItems="flex-start">
          <Stack.Item><Avatar circle src={user.image_url} /></Stack.Item>
          <Stack.Item>{user.display_name}</Stack.Item>
          <Stack.Item>{user.tasks_solved}</Stack.Item>
        </Stack>
      </List.Item>
    );
  };

  function handleViewPublicHandle(username: any) {
    window.location.href = `/@${username}`
  }

  function hasSufficientTopUsers() {
    return topSolvers && topSolvers.count >= 10
  }

  return (
    <div>
      <ShareableTaskDetailsModal taskId={taskId} openTaskList={openTaskList} open={viewShareableTaskDetailsModalOpen} close={handleClose} />
      <PublicHeader
        showSearch={true}
        signIn={signIn}
        signUp={signUp}
        resetPassword={resetPassword}
      />
      <Grid fluid>
        <Row style={{ margin: '20px' }}>
          {hasSufficientTopUsers() &&
            <Col xsHidden smHidden mdHidden lg={13} xl={11} xxl={10}>
              <Panel style={{ width: '100%' }}>
                <Row>
                  <Col>
                    <p><strong>Top Solvers</strong></p>
                    {topSolvers && topSolvers.count === 0 && <p>No solvers available</p>}
                    {topSolvers && topSolvers.count > 0 &&
                      <ul>
                        {topSolvers.results.map((solver: any, index: number) => (
                          <UserListItem index={index + 1} user={solver} />
                        ))}
                      </ul>
                    }
                    {!topSolvers && <Loader size="md" content="Fetching solvers..." />}
                  </Col>
                  <Col>
                    <p><strong>Top Taskers</strong></p>
                    {topTaskers && topTaskers.count === 0 && <p>No taskers available</p>}
                    {topTaskers && topTaskers.count > 0 &&
                      <ul>
                        {topTaskers.results.map((tasker: any, index: number) => (
                          <UserListItem index={index + 1} user={tasker} />
                        ))}
                      </ul>
                    }
                    {!topTaskers && <Loader size="md" content="Fetching taskers..." />}
                  </Col>
                </Row>
              </Panel>
            </Col>
          }
          <Col xs={24} sm={24} md={24} lg={hasSufficientTopUsers() ? 11 : 24} xl={hasSufficientTopUsers() ? 13 : 24} xxl={hasSufficientTopUsers() ? 14 : 24}>
            <Panel header={<Link to="/tasks" className="nonStyledLink">Tasks Browser</Link>}>
              {latestTasks && latestTasks.count === 0 && <p>No tasks available</p>}
              {latestTasks && latestTasks.count > 0 && <>
                <ul>
                  {latestTasks.results.map((task: any, index: number) => (
                    <TaskListItem task={task} onClick={() => handleViewTaskDetails(task.id)} />
                  ))}
                </ul>
              </>}
              {!latestTasks && <Loader size="md" content="Fetching tasks..." />}
            </Panel>
          </Col>
        </Row>
      </Grid>
      <PublicFooter />
    </div>
  );
};