import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'rsuite';
import { getRequest } from "utils/axios";
import { displayCentAmountInDollars } from 'utils/utils';


export default function Search() {
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get('q'); 
    let requestString = `/tasks/?only_posted=true`
    
    if (term) {
      requestString = requestString + "&q=" + term
      setSearchTerm(term); 
    }

    getRequest(requestString).then((res) => {
      setResults(res.data.results);
    }).catch((error) => {
      console.error("Error fetching search results", error);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Grid fluid>
      <Row>
        <Col>
          {searchTerm ?
            <h3>Search Results for "{searchTerm}"</h3>
          :
            <h3>Search Results</h3>
          }
          {loading ? (
            <div>Searching Zipply Tasks...</div>
          ) : (
            <div>
              {results.length === 0 ? (
                <p>No results found.</p>
              ) : (
                results.map((task: any) => (
                  <div key={task.id} style={{ marginBottom: '15px' }}>
                    <h4><Link to={task.shareable_info.url}>{task.title} - {displayCentAmountInDollars(task.reward_in_cents - task.zipply_fee_in_cents)}</Link></h4>
                    <div dangerouslySetInnerHTML={{ __html: task.description }} />
                  </div>
                ))
              )}
            </div>
          )}
        </Col>
      </Row>
    </Grid>
  );
}
