import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getRequest } from "utils/axios";
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import TimeRemaining from 'components/elements/TimeRemaining';
import { displayCentAmountInDollars } from 'utils/utils';

import { Helmet } from "react-helmet-async";
import { Button, Panel, Stack, useToaster, Loader } from 'rsuite';

import logo from "assets/images/Zipply-Logo-Z-196x196.jpg"

import './ShareableTask.css';


interface Props {
  username?: any
  task_name?: any
  task?: any
}


export default function ShareableTask({ username, task_name, task }: Props) {
  const toaster = useToaster();
  const [taskData, setTaskData] = useState<any>(null);
  const [reward, setReward] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (task) {
      setTaskData(task);
      setReward(displayCentAmountInDollars(task.reward_in_cents - task.zipply_fee_in_cents))
      setLoading(false);
    } else if (username && task_name) {
      getRequest(`/tasks/shareable/${username}/${task_name}/`).then((res) => {
        setTaskData(res.data);
        setReward(displayCentAmountInDollars(res.data.reward_in_cents - res.data.zipply_fee_in_cents))
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [task, username, task_name]);

  const handleCopy = () => {
    if (taskData.shareable_info?.url) {
      navigator.clipboard
        .writeText(taskData.shareable_info.url)
        .then(() => {
          toaster.push(<RequestSuccessMessage message="URL copied to clipboard!" toaster={toaster} />);
        })
    }
  };

  if (loading) {
    return (
      <Loader size="md" content="Looking up task details..." />
    );
  }

  if (taskData) {
    return (
      <div className="container">
        <Helmet>
          <title>Discover Creative Solutions on Zipply</title>
          <meta property="og:title" content="Discover Creative Solutions on Zipply" />
          <meta property="og:description" content="Have a task? Need it solved? Explore quick, creative solutions from the Zipply community. Join now to post or solve tasks and earn rewards!" />
          <meta property="og:image" content={window.location.origin + "/shareable-task-sample.png"} />
          <meta property="og:url" content="https://zipply.io" />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Discover Creative Solutions on Zipply" />
          <meta name="twitter:description" content="Have a task? Need it solved? Explore quick, creative solutions from the Zipply community. Join now to post or solve tasks and earn rewards!" />
          <meta name="twitter:image" content={window.location.origin + "/shareable-task-sample.png"} />
        </Helmet>

        <header>
          <Stack spacing={10} alignItems="flex-start" justifyContent="space-around">
            <Stack.Item>
              <Link to="/">
                <img src={logo} alt="Logo" style={{ display: "block", width: "50px" }} />
              </Link>
            </Stack.Item>
            <Stack.Item>
              <h1>{taskData.title + " - " + reward}</h1>
            </Stack.Item>
            <Stack.Item>
              <img src={taskData.shareable_info.qr_code} alt="QR Code" className="qr-image" />
            </Stack.Item>
          </Stack>
          <p className="task-author">
            Posted by: <a href={"/@" + taskData.tasker.username}>{taskData.tasker.display_name} (@{taskData.tasker.username})</a>
          </p>
        </header>

        {/* Task Description Section */}
        <Panel shaded className="task-description">
          <h2>Description</h2>
          <div dangerouslySetInnerHTML={{ __html: taskData.description }} />
          <div className="reward-time">
            <p><strong>Timeframe:</strong> <TimeRemaining endDate={new Date(taskData.due_date)} /></p>
            <p><strong>Reward:</strong> {reward}</p>
          </div>
        </Panel>

        {/* Call to Action */}
        <Panel shaded className="cta">
          <p>Ready to solve this task?</p>
          <Button className="solve-button" href="/signin">
            Sign in to Zipply and Let's Get Started!
          </Button>
        </Panel>

        {/* Social Sharing Section */}
        <Panel shaded className="social-share">
          <p>Share this task:</p>
          <Stack spacing={10} alignItems="center" justifyContent="space-around" wrap>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fab fa-reddit"></i>}
                href={"https://www.reddit.com/submit?title=[Task] " + taskData.title + " - " + reward + "&url=" + taskData.shareable_info.url}
                target="_blank"
                className="reddit-button"
              >
                Reddit
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fab fa-facebook"></i>}
                href={"https://www.facebook.com/sharer/sharer.php?u=" + taskData.shareable_info.url}
                target="_blank"
                className="facebook-button"
              >
                Facebook
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fab fa-twitter"></i>}
                href={"https://twitter.com/intent/tweet?url=" + taskData.shareable_info.url}
                target="_blank"
                className="twitter-button"
              >
                Twitter/X
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fas fa-envelope"></i>}
                href={"mailto:?subject=Check out this task on Zipply&body=" + taskData.shareable_info.url}
                className="email-button"
              >
                Email
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fas fa-copy"></i>}
                onClick={handleCopy}
                className="copy-button"
              >
                Copy
              </Button>
            </Stack.Item>
          </Stack>
        </Panel>

        {/* Footer */}
        <footer>
          <p>
            &copy; 2024 Zipply | <a href="/terms-of-service">Terms of Service</a> | <a href="/privacy-policy">Privacy Policy</a>
          </p>
        </footer>
      </div>
    );
  } else {
    return (
      <div className="container">
        <header>
          <Stack spacing={10} alignItems="flex-start" justifyContent="flex-start">
            <Stack.Item>
              <Link to="/">
                <img src={logo} alt="Zipply Logo" style={{ display: "block", width: "40px" }} />
              </Link>
            </Stack.Item>
            <Stack.Item>
              <h1>Task Not Found</h1>
            </Stack.Item> 
          </Stack>
        
        </header>
    
        {/* Encouraging Message */}
        <Panel shaded className="task-description">
          <h2>Oops! This Task Is No Longer Available</h2>
          <p>
            We're sorry, but the task you were looking for has either been completed or is no longer available.
          </p>
          <p>
            Don't worry! Zipply has a variety of exciting tasks waiting for you to solve. Check them out and earn rewards!
          </p>
        </Panel>
    
        {/* Call to Action */}
        <Panel shaded className="cta">
          <p>Discover More Tasks:</p>
          <Button className="solve-button"  href="/">
            Browse Tasks
          </Button>
        </Panel>
    
        {/* Footer */}
        <footer>
          <p>
            &copy; 2024 Zipply | <a href="/terms-of-service">Terms of Service</a> | <a href="/privacy-policy">Privacy Policy</a>
          </p>
        </footer>
      </div>
    );
  }
}