import React, { useEffect, useState, useRef } from "react";

import { useSelector } from "react-redux";
import { RootState } from "store";

import { Modal, Button, ButtonToolbar, Stack, Panel, Loader, useToaster } from "rsuite";

import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import TimeRemaining from 'components/elements/TimeRemaining';
import { displayCentAmountInDollars } from 'utils/utils';
import { postRequest, getRequest } from "utils/axios";
import ConfirmationDialog from 'components/elements/ConfirmationDialog';

interface Props {
  taskId: any;
  openTaskList: any[];
  open: boolean;
  close: () => void;
}

export default function ShareableTaskDetailsModal({ taskId, openTaskList = [], open, close }: Props) {

  const toaster = useToaster();
  const [currentTaskIndex, setCurrentTaskIndex] = useState<number>(-1);
  const [task, setTask] = useState<any>(null);
  const [reward, setReward] = useState<string>("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [walletState, setWalletState] = useState<any>(null);

  const user = useSelector((state: RootState) => state.auth.user)

  useEffect(() => {
    if (open && taskId && Array.isArray(openTaskList)) {
      const initialIndex = openTaskList.findIndex((task) => task.id === taskId);
      setCurrentTaskIndex(initialIndex >= 0 ? initialIndex : 0);
    }
  }, [taskId, open, openTaskList]);

  useEffect(() => {
    if (currentTaskIndex >= 0 && Array.isArray(openTaskList)) {
      const updatedTask = openTaskList[currentTaskIndex];
      setTask(updatedTask);
      setReward(displayCentAmountInDollars(updatedTask.reward_in_cents - updatedTask.zipply_fee_in_cents))
    }
  }, [open, currentTaskIndex, openTaskList]);

  useEffect(() => {
    if (user && !walletState) {
      getRequest("integrations/stripe/connect/wallets/").then((res) => {
        if (res.status === 200) {
          setWalletState(res.data);
        } else {
          setWalletState({
            can_post_tasks: false,
            can_solve_tasks: false
          })
        }
      });
    }
  }, [walletState]);

  const handlePrevious = () => {
    if (currentTaskIndex > 0) {
      setCurrentTaskIndex(currentTaskIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentTaskIndex < openTaskList.length - 1) {
      setCurrentTaskIndex(currentTaskIndex + 1);
    }
  };

  const handleOpen = () => {
    setShowConfirmationDialog(false);
  }

  const handleTake = () => {
    setShowConfirmationDialog(false);
    postRequest(`/solutions/`, { task_id: task.id })
      .then((res) => {
        handleClose();
        toaster.push(<RequestSuccessMessage message={'Nice! You successfully took a task.'} toaster={toaster} />)
      })
      .catch((err) => {
        toaster.push(<RequestErrorMessage error={err} toaster={toaster} />)
      })
  }

  const handleClose = () => {
    close();
    setTask(null);
    setCurrentTaskIndex(-1);
    setShowConfirmationDialog(false);
  };

  const handleCopy = () => {
    if (task?.shareable_info?.url) {
      navigator.clipboard
        .writeText(task.shareable_info.url)
        .then(() => {
          toaster.push(<RequestSuccessMessage message="URL copied to clipboard!" toaster={toaster} />);
        })
    }
  };

  const isPastDue = (dueDate: any) => {
    return new Date(dueDate) < new Date();
  }

  return (
    <Modal size="lg" open={open} onClose={handleClose} onOpen={handleOpen}>
      <Modal.Header>
        <Modal.Title>
          <Stack spacing={10} alignItems="center" justifyContent="space-around" wrap>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fab fa-reddit"></i>}
                href={"https://www.reddit.com/submit?title=[Task] " + task?.title + " - " + reward + "&url=" + task?.shareable_info?.url}
                target="_blank"
                className="reddit-button"
              >
                Reddit
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fab fa-facebook"></i>}
                href={"https://www.facebook.com/sharer/sharer.php?u=" + task?.shareable_info?.url}
                target="_blank"
                className="facebook-button"
              >
                Facebook
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fab fa-twitter"></i>}
                href={"https://twitter.com/intent/tweet?url=" + task?.shareable_info?.url}
                target="_blank"
                className="twitter-button"
              >
                Twitter/X
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fas fa-envelope"></i>}
                href={"mailto:?subject=Check out this task on Zipply&body=" + task?.shareable_info?.url}
                className="email-button"
              >
                Email
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button size="xs"
                startIcon={<i className="fas fa-copy"></i>}
                onClick={handleCopy}
                className="copy-button"
              >
                Copy
              </Button>
            </Stack.Item>
          </Stack>

          <div style={{marginTop: "20px", marginBottom: "10px", textAlign: "center", alignContent: "center", alignItems: "center", fontSize: "30px", fontWeight: "700", wordWrap: "break-word", whiteSpace: "normal" }}>{task?.title}</div>

          <Stack spacing={10} alignItems="flex-start" justifyContent="space-between" wrap>
            <Stack.Item style={{fontSize: "12px"}}>
              <p><strong>Timeframe:</strong> <TimeRemaining endDate={new Date(task?.due_date)} /></p>
              <p><strong>Reward:</strong> {reward}</p>
            </Stack.Item>
            <Stack.Item>
              <p className="task-author">
                Posted by: <a href={"/@" + task?.tasker.username}>{task?.tasker.display_name} (@{task?.tasker.username})</a>
              </p>
            </Stack.Item>
            <Stack.Item>
              <img src={task?.shareable_info.qr_code} alt="QR Code" className="qr-image" />
            </Stack.Item>
          </Stack>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "500px" }}>
        {!task ?
          <Loader size="md" content="Fetching task details..." />
          :
          <div>
            {/* Task Description Section */}
            <Panel shaded>
              <h2>Description</h2>
              <div dangerouslySetInnerHTML={{ __html: task.description }} />
            </Panel>
          </div>
        }
      </Modal.Body>
      <Modal.Footer style={{ marginTop: "10px" }}>
        <Stack spacing={10} alignItems="center" justifyContent="space-between">
          <Stack.Item>
            {openTaskList?.length > 1 &&
              <ButtonToolbar>
                <Button color="green" appearance="primary" onClick={handlePrevious} disabled={currentTaskIndex <= 0}>
                  Previous
                </Button>
                <Button color="green" appearance="primary" onClick={handleNext} disabled={currentTaskIndex >= (openTaskList?.length || 0) - 1}>
                  Next
                </Button>
              </ButtonToolbar>
            }
          </Stack.Item>
          <Stack.Item>
            {openTaskList?.length > 1 &&
              <span>Solvable Task {currentTaskIndex + 1} of {openTaskList?.length || 0}</span>
            }
          </Stack.Item>
          <Stack.Item>
            {task?.current_status === 'POSTED' &&
              <ConfirmationDialog open={showConfirmationDialog} handleClose={() => setShowConfirmationDialog(false)} handleConfirm={handleTake} title="Earnings Acknwoledgement for Solving a Task">
                <div style={{ fontSize: "12px" }}>
                  <p>As a solver, your earnings will be available once a tasker accepts your solution, but they will be classified as deferred earnings during the MVP phase of Zipply.</p>

                  <p>Payments are deferred for 30 days after acceptance and will be automatically paid out on the 28th of each month. A verified payout account through Stripe Connect is required to access your funds.</p>

                  <p>We recognize this may limit some users, and we plan to introduce more payout options in the future.</p>

                  <p>Please confirm your understanding of this payment structure by clicking the <strong>Confirm</strong> button below.</p>
                </div>
              </ConfirmationDialog>
            }
            {user ? <>
              {(task?.current_status === 'POSTED' && (task?.invite_only || isPastDue(task.due_date))) && <Button appearance="primary" disabled={!walletState?.can_solve_tasks} color="green" onClick={() => setShowConfirmationDialog(true)}>Request Invite</Button>}
              {task?.current_status === 'POSTED' && !task?.invite_only && !isPastDue(task.due_date) && <Button appearance="primary" disabled={!walletState?.can_solve_tasks} color="green" onClick={() => setShowConfirmationDialog(true)}>Solve</Button>}
            </>
              :
              <Button appearance="primary" color="green" href="/signin">Get Started!</Button>
            }
          </Stack.Item>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
