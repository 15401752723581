import { text } from 'stream/consumers';
import { GeoLocation } from 'utils/types'

export function handleViewPublicHandle(username: any) {
  window.location.href = `/@${username}`
}

export function displayFee(fee: any) {
  if (fee) {
    if (fee.flatFeeInCents === 0) {
      return fee.feePercentage + '%'
    } else if (fee.feePercentage === 0) {
      return displayCentAmountInDollars(fee.flatFeeInCents)
    } else {
      return fee.feePercentage + '% + ' + displayCentAmountInDollars(fee.flatFeeInCents)
    }
  } else {
    return "FEE_DISPLAY_ERROR!"
  }
}

export const displayCentAmountInDollars = (amount: number) => {
  const dollars = amount / 100;
  
  if (dollars % 1 === 0) {
    return '$' + dollars.toFixed(0);
  } else {
    return '$' + dollars.toFixed(2);
  }
}

export const mapTaskStatus = (status: any) => {
  switch (status) {
    case 'HOLD':
      return { color: 'red', text: status };
    case 'CANCEL REQUEST':
      return { color: 'orange', text: status };
    case 'CANCELED':
      return { color: 'red', text: status };
    case 'PAYMENT FAILED':
      return { color: 'red', text: status };
    default:
      return { color: 'green', text: status };
  }
};

export const mapSolutionStatus = (status: any) => {
  switch (status) {
    case 'CANCELED':
      return { color: 'red', text: status };
    case 'HOLD':
      return { color: 'red', text: status };
    case 'REJECTED':
      return { color: 'red', text: status };
    case 'DECLINED INVITATION':
      return { color: 'red', text: status };
    case 'REQUESTED INVITATION':
      return { color: 'yellow', text: status };
    default:
      return { color: 'green', text: status };
  }
};


export const convertDollarsToCents = (amount: number) => {
  return amount * 100
}

export const setSessionData = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionData = (key: string) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const removeSessionData = (key: string) => {
  sessionStorage.removeItem(key);
}

export function getCookie(name: any) {
  let cookieValue = null;

  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();

          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

              break;
          }
      }
  }

  return cookieValue;
}  

export function capitalize(word: any) {
    if (word && word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase()
    } else {
        return undefined
    }
  }

export function capitalizeAll(words: string) {
    if (words && words.length > 0) {
        return words.split(' ').map((word:any) => word[0].toUpperCase() + word.slice(1)).join(' ');
    } else {
        return undefined
    }    
  }  

export function removeDuplicates(items: any[]) {
  let uniqueItems: any = [];

  items.forEach(function(item) {
    if (uniqueItems.indexOf(item) === -1) {
      uniqueItems.push(item);
    }
  });

  return uniqueItems
}

export function sortJsonObject(array: any, sortColumn: any, sortType: any) {
    if (sortColumn && sortType)  {
        return array.sort((row1: any, row2: any) => {
            var value1
            var value2
            // Sort support for nested objects of depth one
            const sortColumns = sortColumn.split('.')
            if (sortColumns.length > 1) {
                value1 = row1[sortColumns[0]][sortColumns[1]];
                value2 = row2[sortColumns[0]][sortColumns[1]];
            } else {
                value1 = row1[sortColumn];
                value2 = row2[sortColumn];
            }

            var comparison = 0
            
            if (typeof value1 === 'string' || typeof value2 === 'string' ) {
                if (value1) 
                    value1 = value1.toString().toLowerCase().trim()
                else
                    value1 = ''

                if (value2)
                    value2 = value2.toString().toLowerCase().trim()
                else
                    value2 = ''
            }

            if (value1 !== value2)
                comparison = (value1 < value2) ? -1 : 1

            if (sortType === 'asc') {
                return comparison
            } else {
                return comparison * -1
            }
        });
    }

    return array;
}

export function renderLocation(geoLocation: GeoLocation) {
    if (geoLocation) {
      let location = []
      if (geoLocation.city)
        location.push(geoLocation.city)
      if (geoLocation.state_iso)
        location.push(geoLocation.state_iso)
      if (geoLocation.country_iso)
        location.push(geoLocation.country_iso)
      return location.join(', ')
    } else {
      return 'Unknown'
    }
  }

  export function renderYearsOfExperience(startDate: any) {
    if (startDate) {
      const years_experience = new Date().getFullYear() - new Date(startDate).getFullYear();
      return years_experience + ' years'
    }
  }

  export function monthDiff(date1: Date, date2: Date) {
    var months: number;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
  }

  export function timeSpentAtPlace(startDateValue: Date, endDateValue: Date) {
    if (!startDateValue) {
      return ''
    }

    var endDate;
    if (!endDateValue) {
      endDate = new Date()
    } else {
      endDate = new Date(endDateValue)
    }

    const totalMonths: number = monthDiff(new Date(startDateValue), endDate)
    const years = Math.trunc(totalMonths / 12)
    const months = totalMonths % 12

    var yearName = " years "
    if (years === 1) {
      yearName = " year "
    }
    var monthName = " months "
    if (months === 1) {
      monthName = " month "
    }
    if (years > 0 && months > 0) {
      return years + yearName + months + monthName
    } else if (years > 0) {
      return years + yearName
    } else {
      return months + monthName
    }
  }

  export const percentageScoreToStars = (score: number, number_stars: number) => {
    return Math.round(score * number_stars)
  }

  export const scoreToStars = (score: number, scoreMin: number, scoreMax:number, numberStars: number) => {
    const adjustedScore = score / (scoreMax - scoreMin)
    return Math.round(adjustedScore * numberStars)
  }

  export const fractionScoreToPercentage = (score: number) => {
    return Math.round(100 * score)
  }