import { useLocation, Navigate } from 'react-router-dom';
import PublicProfile from './PublicProfile';
import ShareableTask from './ShareableTask';

const CustomRoute = () => {
    const location = useLocation();

    if (location.pathname.startsWith('/@')) {
        if (location.pathname.includes('/task/')) {
            // Example: /@user1/task/create-logo-coffee-shop
            const username = location.pathname.split('@')[1].split('/')[0];
            const task_name = location.pathname.split('/')[3];
            return <ShareableTask username={username} task_name={task_name} />;
        } else {
            const username = location.pathname.split('@')[1]; 
            return <PublicProfile username={username} />;
        }
    } else {
        return <Navigate to="/" />;
    }
};

export default CustomRoute;
