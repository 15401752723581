import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { User } from 'types';

const GoogleAnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        try {
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
                ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
            }
        } catch (error) {
            console.error("Google Analytics pageview tracking error:", error);
        }
    }, [location]);

    return null;
};

export default GoogleAnalyticsTracker;

type GAEventProps = {
    category: string;
    action: string;
    label: string;
};

export const GAEvent = ({ category, action, label }: GAEventProps) => {
    const location = useLocation();

    useEffect(() => {
        try {
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
                ReactGA.event({
                    category: category,
                    action: action,
                    label: label,
                });
            }
        } catch (error) {
            console.error("Google Analytics event tracking error:", error);
        }
    }, [location]);

    return null;
};

export const sendGAEvent = (category: any, action: any, label: any) => {
    try {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
            ReactGA.event({
                category,
                action,
                label,
            });
        }
    } catch (error) {
        console.error("Google Analytics sendGAEvent error:", error);
    }
};

export const sendGAUserSignIn = (user: User, account: string) => {
    try {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
            const userProperties = {
                username: user.username,
                email: user.email,
                name: user.full_name,
            };
            ReactGA.set({ user_id: user.id, business_id: user.business_id, ...userProperties });
            ReactGA.event({
                category: "user",
                action: "sign_in",
                label: "User signed in with " + account,
            });
        }
    } catch (error) {
        console.error("Google Analytics user sign-in tracking error:", error);
    }
};

export const sendGAUserSignUp = (user: User) => {
    try {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
            const userProperties = {
                username: user.username,
                email: user.email,
                name: user.full_name,
            };
            ReactGA.set({ user_id: user.id, business_id: user.business_id, ...userProperties });
            ReactGA.event({
                category: "user",
                action: "sign_up",
                label: "User signed up with account",
            });
        }
    } catch (error) {
        console.error("Google Analytics user sign-up tracking error:", error);
    }
};
