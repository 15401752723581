import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Configuration } from "utils/types";

const initialState: Configuration = {
  updated: false,
  fees: {
    task: {
      feePercentage: 0,
      flatFeeInCents: 0
    },
    taskRefund: {
      feePercentage: 0,
      flatFeeInCents: 0
    },
    referral: {
      feePercentage: 0,
      flatFeeInCents: 0
    },
    stripeCharge: {
      feePercentage: 0,
      flatFeeInCents: 0
    },
    stripePayout: {
      feePercentage: 0,
      flatFeeInCents: 0
    }
  }
}

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setConfiguration(configuration: Configuration, action: PayloadAction<any>) {
      configuration.updated = true;
      configuration.fees = action.payload.fees;
    }
  }
});

export const { setConfiguration } = configurationSlice.actions;
export default configurationSlice;
