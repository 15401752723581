import { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { Modal, Button, Stack, Grid, Row, Col, Form, Input, InputNumber, useToaster, Toggle, Progress, Loader, Tabs } from 'rsuite';

import { useSelector } from "react-redux";
import {RootState} from "store";

import FileUploader from 'components/files/FileUploader';
import FileUploadList from 'components/files/FileUploadList';
import DueDatePicker from 'components/elements/DueDatePicker';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestConflictMessage from 'components/toasts/RequestConflictMessage';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import ZipplyEditor from 'components/editor/ZipplyEditor';
import { postRequest, putRequest, putFileRequest, getRequest } from "utils/axios";
import { convertDollarsToCents, displayFee } from 'utils/utils';

interface Props {
  open: boolean;
  close: () => void;
}

export default function CreateTaskModal({ open, close }: Props) {
  const configuration: any = useSelector((state: RootState) => state.configuration);

  const maxFilesPermittedForUpload = 5 // TODO: configuration.features.max_files_permitted_for_upload;
  const defautlCandidateUploadFileSize = '10485760' // 10MB in case it's not defined
  const maxUploadFileSize = parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || defautlCandidateUploadFileSize)

  const defaultDueDate = new Date(new Date().setDate(new Date().getDate() + 7)); // Initial due date is 7 days from now
  const toaster = useToaster();

  const descriptionEditorRef: any = useRef();
  const setDescriptionEditorRef = (ref: any) => {
    descriptionEditorRef.current = ref;
  }

  const protectedDescriptionEditorRef: any = useRef();
  const setProtectedDescriptionEditorRef = (ref: any) => {
    protectedDescriptionEditorRef.current = ref;
  }

  const [title, setTitle] = useState('');
  const [protectedDescription, setProtectedDescription] = useState('');
  const [reward, setReward] = useState<number>(10);
  const [fileList, setFileList] = useState<any>([]);
  const [dummyFileList, setDummyFileList] = useState<any>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState<any>(defaultDueDate);
  const [acceptSolutionsEarly, setAcceptSolutionsEarly] = useState<boolean>(true);
  const [inviteOnly, setInviteOnly] = useState<boolean>(false);
  const [walletState, setWalletState] = useState<any>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState<any>('task-description');

  const [uploadProgress, setUploadProgress] = useState<{ percent: number; loadedKb: number; totalKb: number }>({
    percent: 0,
    loadedKb: 0,
    totalKb: 0
  });

  useEffect(() => {
    if (!walletState) {
      getRequest("integrations/stripe/connect/wallets/").then((res) => {
        if (res.status === 200) {
          setWalletState(res.data);
        } else {
          setWalletState({
            can_post_tasks: false,
            can_solve_tasks: false
          })
        }
      });
    }
  }, [walletState]);

  function handleOpen() {
    setShowConfirmationDialog(false);
    setTitle('');
    setProtectedDescription('');
    setReward(10);
    setFileList([]);
    setDummyFileList([]);
    setIsUploading(false);
    setDueDate(defaultDueDate);
    setAcceptSolutionsEarly(true);
    setInviteOnly(false);
    setWalletState(null);
  }

  const handleSubmitWithConfirmation = () => {
    const description = descriptionEditorRef.current?.getContent();
    const protectedDescription = protectedDescriptionEditorRef.current?.getContent();

    if (!title || !description || !reward || !dueDate) {
      toaster.push(<RequestConflictMessage message={'Please specify at least a title, description, reward, and a due date.'} toaster={toaster} />);
      return
    }
    if (title.length > 100) {
      toaster.push(<RequestConflictMessage message={'Title is too long. Please limit it to 100 characters.'} toaster={toaster} />);
      return
    }
    if (description.length > 5000) {
      toaster.push(<RequestConflictMessage message={'Description is too long. Please limit it to 5,000 characters.'} toaster={toaster} />);
      return
    }
    if (protectedDescription.length > 10000) {
      toaster.push(<RequestConflictMessage message={'Protected description is too long. Please limit it to 10,000 characters.'} toaster={toaster} />);
      return
    }
    setShowConfirmationDialog(true);
  };

  const handleSubmit = async () => {
    setShowConfirmationDialog(false);
    const hasFiles = fileList.length > 0;
    const task = {
      title,
      description: descriptionEditorRef.current.getContent(),
      protected_description: protectedDescriptionEditorRef.current.getContent(),
      reward_in_cents: convertDollarsToCents(reward),
      accept_solutions_early: acceptSolutionsEarly,
      invite_only: inviteOnly,
      due_date: dueDate
    };

    try {
      const res = await postRequest(`/tasks/`, task);
      if (res.status === 201) {
        const taskId = res.data.id;

        if (hasFiles) {
          try {
            await uploadFiles(taskId);
          } catch (uploadError) {
            toaster.push(<RequestErrorMessage error="An error occurred while uploading task files. Please try again." toaster={toaster} />);
            return; // Stop execution if file upload fails
          }
        }

        await putRequest(`/tasks/${taskId}/`, { status: 'POSTED' });
        toaster.push(<RequestSuccessMessage message="Congratulations! You successfully posted a new task." toaster={toaster} />);
      } else {
        throw new Error("Unexpected error while posting a new task");
      }
    } catch (err: any) {
      toaster.push(<RequestErrorMessage error={err || "An error occurred while processing your request."} toaster={toaster} />);
    } finally {
      handleClose();
    }
  };

  async function uploadFiles(taskId: any) {
    let hasError = false;

    setUploadProgress({ percent: 0, loadedKb: 0, totalKb: 0 });
    setIsUploading(true);

    const filesToUpload = fileList.filter((file: any) => file.status === 'selected');
    const totalSize = filesToUpload.reduce((sum: any, file: any) => sum + file.blobFile.size, 0);
    let totalLoaded = 0;

    const uploadFile = async (file: any) => {
      try {
        updateFileStatus(file.fileKey, 'uploading');

        const response = await postRequest(`/tasks/${taskId}/files/`, { file_name: file.name, content_type: file.type });
        const { id, url, content_type: contentType } = response.data;
        let previousLoaded = 0;

        const config = {
          onUploadProgress: (progressEvent: any) => {
            const loadedForThisFile = progressEvent.loaded - previousLoaded;
            previousLoaded = progressEvent.loaded;

            totalLoaded += loadedForThisFile;
            setUploadProgress({
              percent: Math.round((totalLoaded * 90) / totalSize),
              loadedKb: Math.round(totalLoaded / 1024),
              totalKb: Math.round(totalSize / 1024)
            });
          }
        };

        await putFileRequest(url, file.blobFile, config.onUploadProgress, { 'Content-Type': contentType });
        updateFileStatus(file.fileKey, 'processing');

        await putRequest(`/tasks/${taskId}/files/${id}/`, { task_id: taskId, status: 'UPLOADED' });
        updateFileStatus(file.fileKey, 'uploaded');
      } catch (error: any) {
        updateFileStatus(file.fileKey, 'error');
        toaster.push(<RequestErrorMessage error={error.message || "An error occurred during the upload process."} toaster={toaster} />);
        hasError = true;
      }
    };

    for (const file of filesToUpload) {
      await uploadFile(file);
    }

    setUploadProgress({ percent: 100, loadedKb: Math.round(totalSize / 1024), totalKb: Math.round(totalSize / 1024) });
    setIsUploading(false);

    if (hasError) {
      throw new Error('One or more files failed to upload.');
    }
  }


  const updateFileStatus = (fileKey: string, status: string) => {
    setFileList((prevFileList: any) =>
      prevFileList.map((file: any) =>
        file.fileKey === fileKey ? { ...file, status } : file
      )
    );
  };

  const handleClose = () => {
    close();
  };

  const handleFileChange = (updatedFileList: any) => {
    setDummyFileList([]);
    console.log("handleFileChange", updatedFileList);
    const filteredFiles = updatedFileList.filter((file: any) => {
      if (file.blobFile.size > maxUploadFileSize) {
        toaster.push(<RequestConflictMessage message={`File "${file.blobFile.name}" exceeds the ${maxUploadFileSize}MB size limit.`} toaster={toaster} />);
        return false;
      }
      return true
    });

    const totalFiles = fileList.length + filteredFiles.length;
    if (totalFiles > maxFilesPermittedForUpload) {
      toaster.push(<RequestConflictMessage message={`You can upload a maximum of ${maxFilesPermittedForUpload} files per task.`} toaster={toaster} />);
      return;
    }

    // Update fileList with both the metadata and actual file content
    const updatedFilesWithStatus = filteredFiles.map((file: any) => ({
      ...file,
      status: 'selected',
      blobFile: file.blobFile // Ensure this contains the actual file content
    }));

    // Prevent duplicate files from being added
    setFileList((prevFileList: any) => {
      const newFiles = updatedFilesWithStatus.filter(
        (file: any) => !prevFileList.some((f: any) => f.fileKey === file.fileKey)
      );
      return [...prevFileList, ...newFiles];
    });
  };

  function removeFile(fileKey: string) {
    setFileList((prevFileList: any) =>
      prevFileList.filter((file: any) => file.fileKey !== fileKey)
    );
  }

  const handlePriceChange = (value: any) => {
    setReward(value);
  };

  return (
    <Modal
      size="lg"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      backdrop="static">
      <Modal.Header>
        <Modal.Title>Post a New Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form fluid>
                <Stack spacing={10}>
                  <Stack.Item grow={1}>
                    <Form.Group controlId="task-title">
                      <Form.ControlLabel>Title <sup>Visible to everyone</sup></Form.ControlLabel>
                      <Input value={title} onChange={setTitle} placeholder="Enter task title" />
                    </Form.Group>
                  </Stack.Item>
                  <Stack.Item basis="100px">
                    <Form.Group controlId="task-price">
                      <Form.ControlLabel>Reward</Form.ControlLabel>
                      <InputNumber
                        value={reward}
                        onChange={handlePriceChange}
                        placeholder="Enter max price"
                        min={5}
                        max={100}
                        step={1}
                        formatter={value => `$${value}`}
                      />
                    </Form.Group>
                  </Stack.Item>
                </Stack>
                <Stack spacing={20} style={{ marginTop: "5px" }}>
                  <Stack.Item basis="200px">
                    <Form.Group controlId="task-due-date">
                      <Form.ControlLabel>Due Date</Form.ControlLabel>
                      <DueDatePicker dueDate={dueDate} setDueDate={setDueDate} />
                    </Form.Group>
                  </Stack.Item>
                  <Stack.Item basis="200px">
                    <Form.Group controlId="accept-early">
                      <Form.ControlLabel>Accept Solutions Early</Form.ControlLabel>
                      <Toggle
                        checked={acceptSolutionsEarly}
                        onChange={value => setAcceptSolutionsEarly(value)}
                      />
                    </Form.Group>
                  </Stack.Item>
                  <Stack.Item basis="300px">
                    <Form.Group controlId="requires-approval">
                      <Form.ControlLabel>By Invite Only</Form.ControlLabel>
                      <Toggle
                        checked={inviteOnly}
                        onChange={value => setInviteOnly(value)}
                      />
                    </Form.Group>
                  </Stack.Item>
                </Stack>

                <Tabs activeKey={tabActiveKey} onSelect={setTabActiveKey} id="tabs" style={{ marginTop: "10px" }}>
                  <Tabs.Tab eventKey="task-description" title="Overview">
                    <div style={{ height: "400px" }}>
                      <Form.Group controlId="task-description">
                        <Form.HelpText>Brief description visible to anyone signed in</Form.HelpText>
                        <ZipplyEditor key="task-description" content="" setEditorRef={setDescriptionEditorRef} readOnly={false} height={250} />
                      </Form.Group>
                    </div>
                  </Tabs.Tab>

                  <Tabs.Tab eventKey="task-protected-description" title="Protected Description">
                    <div style={{ height: "400px" }}>
                      <Form.Group controlId="protected-description">
                        <Form.HelpText>Detailed description visible only to invited solvers</Form.HelpText>
                        <ZipplyEditor key="task-description" content="" setEditorRef={setProtectedDescriptionEditorRef} readOnly={false} height={250} />
                      </Form.Group>
                    </div>
                  </Tabs.Tab>

                  <Tabs.Tab eventKey="task-files" title="Files">
                    <div style={{ height: "400px" }}>
                      <Form.Group controlId="task-files">
                        <Form.HelpText>Attach files visible only to invited solvers</Form.HelpText>
                        <FileUploadList
                          fileList={fileList}
                          onRemove={removeFile}
                          isUploading={isUploading}
                        />
                        <Form.Control
                          name="file"
                          accepter={FileUploader}
                          autoUpload={false}
                          draggable
                          fileListVisible={false}
                          fileList={dummyFileList}
                          multiple={true}
                          onChange={handleFileChange}
                          disabled={isUploading}
                        />
                      </Form.Group>
                    </div>
                  </Tabs.Tab>
                </Tabs>
              </Form>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Grid fluid style={{ marginTop: "5px" }}>
          <Row>
            <Col xs={18} style={{ textAlign: "left" }}>
              {walletState?.can_post_tasks === false ? (
                <span style={{ fontSize: "14px", color: "red" }}>In order to post a task, please configure a payment source under <Link onClick={handleClose} to="/wallet">Manage Wallet</Link> first.</span>
              ) : (<>
                {isUploading &&
                  <div>
                    <Progress.Line
                      percent={uploadProgress.percent}
                      strokeColor="#4caf50"
                      showInfo={true}
                    />
                    <div style={{ marginLeft: "12px", fontSize: "10px" }}>
                      {uploadProgress.loadedKb} KB / {uploadProgress.totalKb} KB uploaded
                    </div>
                  </div>
                }
                {isUploading &&
                  <Loader size="sm" content={`Processing files...`} />
                }
              </>)}
            </Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              <ConfirmationDialog open={showConfirmationDialog} handleClose={() => setShowConfirmationDialog(false)} handleConfirm={handleSubmit} title="Confirm Your Task Posting">
                <div style={{ fontSize: "12px" }}>
                  <p>We’re excited to help you get your task done! Before you post, please review the payment details:</p>

                  <ul>
                    <li>The solver will receive the reward minus a <strong>{displayFee(configuration.fees?.task)} service fee</strong>. This helps us maintain a secure and efficient platform for everyone.</li>

                    <li>To guarantee compensation for our solvers, we will authorize a payment when you post your task and charge your card after the first solution is submitted.</li>
                    <li><strong>Important:</strong> If no solutions are submitted for your task, you will receive a full refund.</li>
                    <li>Please note: A <strong>{displayFee(configuration.fees?.taskRefund)} convenience fee</strong> applies for cancellations after solutions are available.</li>
                  </ul>

                  <p>We’re here to make the process smooth and enjoyable. Please confirm that you understand this payment structure by clicking the <strong>Confirm</strong> button below.</p>
                </div>
              </ConfirmationDialog>
              <Button disabled={!walletState?.can_post_tasks} appearance="primary" onClick={handleSubmitWithConfirmation}>Post Task</Button>
              <Button onClick={handleClose} appearance="subtle">Cancel</Button>
            </Col>
          </Row>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
}
