import { useState, useEffect } from 'react';
import { Modal, Input, Panel, PanelGroup } from 'rsuite';

import { sendGAEvent } from 'components/tracker/GoogleAnalyticsTracker';

import { useSelector } from "react-redux";
import {RootState} from "store";
import { displayFee } from 'utils/utils';

interface Props {
    open: boolean;
    close: () => void;
}

interface FAQItem {
    question: string;
    answer: string;
}

const FAQModal = ({ open, close }: Props) => {
    const configuration: any = useSelector((state: RootState) => state.configuration);

    useEffect(() => {
        sendGAEvent("FAQ", "View", "FAQ Page");
    }, []);

    const handleClose = () => {
        close();
        setSearchTerm('');
    };

    const faqData: FAQItem[] = [
        {
            question: "What is the purpose of the platform?",
            answer: "Our platform allows you to delegate small tasks that save you time, enhance creativity, or make everyday life more enjoyable."
        },
        {
            question: "How do I create a task?",
            answer: "To create a task, simply add a title, provide a description and optional files, and set your desired budget. Our system will handle the rest!"
        },
        {
            question: "What type of tasks can I delegate?",
            answer: "You can delegate anything from content creation, research, design work, video editing, personal assistance, and more! Click the Ideas tab for a detailed list of possibilities."
        },
        {
            question: "How does the pricing work?",
            answer: "Each task offers a reward, currently ranging from $5 to $100, based on its complexity and required time."
        },
        {
            question: "How do I know my task will be done well?",
            answer: "You can choose to open your task to all solvers or require them to request an invitation. Each solver has a public profile with past activity and reviews, so you can assess quality and reliability based on feedback from previous tasks."
        },
        {
            question: "Can I track the progress of my tasks?",
            answer: "Yes, you’ll be notified when tasks are accepted or when solvers submit solutions for review. You can also communicate directly with the solver during the task. In your profile, you can opt out of notifications if you prefer to check the platform manually."
        },
        {
            question: "What if I'm not satisfied with the work?",
            answer: "If you're not happy with a submitted solution, you can request a cancellation. We will review your request and make a final decision about issuing a refund. Please see our refund policy for more details."
        },
        {
            question: "What are the fees associated with using Zipply?",
            answer: "Zipply charges a " + displayFee(configuration.fees?.task) + " service fee on each task posted, which is taken from the total reward before solvers are paid. Additionally, if a task is canceled or refunded after submissions are available, a convenience fee of " + displayFee(configuration.fees?.taskRefund) + " will be charged to the tasker."
        },
        {
            question: "When will I be charged?",
            answer: "You will be charged as soon as the first solution is submitted by a solver. If you cancel your task before any solutions are submitted, no additional fees will be applied."
        },
        {
            question: "Are there any hidden fees?",
            answer: "No, there are no hidden fees. The total reward you set for your task is the amount you will be charged, plus any applicable convenience fees if a task is canceled or refunded after submissions are available."
        },
        {
            question: "How do I get charged?",
            answer: "Only taskers are charged, and payments are securely handled through Stripe. Simply add a payment method to your account before posting a task."
        },
        {
            question: "Can I cancel a task once it's created?",
            answer: "Yes, you can cancel a task before it has received any submissions from solvers. After a solver has submitted a solution, you can still request a cancellation, but we will review your request. Please see our refund policy for more details."
        },
        {
            question: "How is Zipply different from platforms like Reddit r/slavelabour, Mechanical Turk, Upwork, Fiverr, and TaskRabbit?",
            answer: "Zipply eliminates the process overhead and long involvement required by many other platforms. For example, Reddit’s informal setup requires negotiating and betting on solvers, while Fiverr often involves spending time finding and trusting a single solver, which can be hit-or-miss. Platforms like Upwork or Fiverr may also require lengthy listings or contracts. With Zipply, you can post a task quickly, set a reward, and choose from multiple solutions without worrying about time-consuming searches or risks. Zipply focuses on quick, creative, and everyday tasks for a broad audience, including students, hobbyists, and non-tech users. Unlike Mechanical Turk’s microdata tasks or TaskRabbit’s focus on physical labor, Zipply offers a structured digital environment with deadlines, rewards, and a marketplace for generic solutions, providing solvers with more earning opportunities and taskers with efficient results."
        },
        {
            question: "Can I share my task on social media?",
            answer: "Yes, you can share your task on social media to reach a wider audience. Use the built-in buttons for Reddit, Facebook, or Twitter/X to share directly, or copy the task URL and paste it into your posts. Additionally, we offer a QR code feature for convenient sharing."
        },
        { 
            question: "How do I become a solver?", 
            answer: "To become a solver, sign up on our platform and start browsing available tasks that match your skills." 
        },
        { 
            question: "How do I get paid?", 
            answer: "Once you complete a task and the task creator approves your submission, the payment will appear in your account. Payments are deferred for 30 days after acceptance, after which they are automatically paid out during the next payment cycle on the 28th of each month. Please note that a verified payout account through Stripe Connect is required." 
        },
        {   
            question: "Why do I need Zipply when I have ChatGPT?",
            answer: "Zipply allows you to delegate tasks to real people, while ChatGPT is a language model designed for text generation and other language tasks. Zipply is ideal for tasks that require human creativity, intuition, or judgment, while ChatGPT assists with text generation, summarization, and similar tasks. We encourage solvers to leverage both platforms together for the best results."
        },
        {
            question: "How can I get in touch with the Zipply team?",
            answer: "We're here to help! You can reach us through our contact form accessible when you are signed in under the avatar menu. For product questions, best practices, or community support, we highly recommend joining our Discord community. We have an active community of solvers and task creators who are always ready to help. Click the join link under the avatar in the top right corner to join our Discord community."
        },
        {
            question: "What happens when my task is overdue?",
            answer: "Once a task reaches its due date, it will still display as 'POSTED' but will indicate 'Review' to signal that the tasker needs to make a decision based on the submissions. Solvers will not be able to submit any solutions after the due date, but they can request an invitation for late submissions."
        },
        {
            question: "Can solvers submit solutions after the due date?",
            answer: "No, solvers cannot submit solutions after the due date. However, they can request to be invited for late submissions, which taskers can manage based on their discretion."
        },
        {
            question: "What is the grace period for overdue tasks?",
            answer: "For tasks that are past their due date, Zipply allows the tasker a 7-day grace period to select a solution. During this time, taskers can review submissions and make their decision."
        },
        {
            question: "How are refunds processed for overdue tasks?",
            answer: "If the tasker does not make a decision within the 7-day grace period, Zipply will take action based on the submissions: if solutions have been submitted, Zipply will award the task's reward to a top solver; if no solutions have been submitted, the tasker will receive a refund, minus any applicable convenience fees."
        },
        {
            question: "Do you have a referral program?",
            answer: "Yes, we have a referral program! For every friend you refer, you’ll earn " + displayFee(configuration.fees?.referral) + " of their reward from the first task they solve or post successfully. Help them discover a fast, easy way to tackle tasks, and let’s grow the Zipply community together!"
        }
    ]

    // State for filtering FAQs
    const [searchTerm, setSearchTerm] = useState('');
    const filteredFAQs = faqData.filter(faq =>
        faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Modal 
            size="lg" 
            open={open} 
            onClose={handleClose}>
            <Modal.Header>
                <Modal.Title><div style={{textAlign: "center", margin: "5px", fontSize: "20px", fontWeight: "800", color: "#4eab0d"}}>Making Sure You Know What to Expect!</div></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '800px', overflowY: 'auto' }}>
                <Input
                    placeholder="Search FAQs..."
                    value={searchTerm}
                    onChange={setSearchTerm}
                    style={{ marginBottom: 20 }}
                />
                <PanelGroup accordion>
                    {filteredFAQs.map((faq, index) => (
                        <Panel key={index} header={faq.question} bordered>
                            {faq.answer}
                        </Panel>
                    ))}
                </PanelGroup>
            </Modal.Body>
        </Modal>
    );
};

export default FAQModal;
