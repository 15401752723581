import { useEffect, useState, useRef} from "react";

import { patchRequest } from "utils/axios";
import { Modal, Button, Grid, Row, Form, useToaster, Loader, Col, Progress, Badge, IconButton, Tabs } from 'rsuite';

import MessageIcon from '@rsuite/icons/Message';
import RemoveIcon from '@rsuite/icons/WarningRound';

import TaskMessaging from 'components/communication/TaskMessaging';
import FileUploader from 'components/files/FileUploader';
import FileUploadList from 'components/files/FileUploadList';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestConflictMessage from 'components/toasts/RequestConflictMessage';
import { getRequest, postRequest, putRequest, putFileRequest, deleteRequest } from "utils/axios";
import TaskFeedbackModal from "components/feedback/TaskFeedbackModal";
import FilePreview from "components/files/FilePreview";
import TaskDetails from 'components/tasks/TaskDetails';
import ZipplyEditor from 'components/editor/ZipplyEditor';

const acceptedFormats = ['.pdf', '.doc', '.docx', '.txt', '.rtf', '.png', '.jpg', '.jpeg', '.gif'];

interface Props {
  open: boolean;
  taskId: any;
  close: () => void;
}

export default function SolverSolutionModal({ open, taskId, close }: Props) {
  const maxFilesPermittedForUpload = 5 // TODO: configuration.features.max_files_permitted_for_upload;
  const defautlCandidateUploadFileSize = '10485760' // 10MB in case it's not defined
  const maxUploadFileSize = parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || defautlCandidateUploadFileSize)

  const toaster = useToaster();

  const [initialSolutionDescription, setInitialSolutionDescription] = useState<any>('');
  const solutionDescriptionEditorRef: any = useRef();
  const setSolutionDescriptionEditorRef = (ref: any) => {
    solutionDescriptionEditorRef.current = ref;
  }

  const [fileList, setFileList] = useState<any>([]);
  const [dummyFileList, setDummyFileList] = useState<any>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [solutionStatus, setSolutionStatus] = useState<string>('');
  const [taskMessagingModal, setTaskMessagingModalOpen] = useState<boolean>(false);
  const [taskFeedbackModalOpen, setTaskFeedbackModalOpen] = useState<boolean>(false);
  const [task, setTask] = useState<any>(null);
  const [tabActiveKey, setTabActiveKey] = useState<any>('solution-description');
  const [solutionReadOnly, setSolutionReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (taskId) {
      refreshTask()
    }
  }, [open, taskId]);


  const refreshTask = () => {
    getRequest(`tasks/${taskId}/`).then((res: any) => {
      setTask(res.data);
      setSolutionStatus(res.data.my_solution.current_status);
      setSolutionReadOnly(res.data.my_solution.current_status !== 'INVITED');
      setInitialSolutionDescription(res.data.my_solution.description || '');
      setFileList([]);
    })
  }

  const [uploadProgress, setUploadProgress] = useState<{ percent: number; loadedKb: number; totalKb: number }>({
    percent: 0,
    loadedKb: 0,
    totalKb: 0
  });

  const handleClose = () => {
    close();
    setInitialSolutionDescription('');
    setFileList([]);
    setDummyFileList([]);
    setUploadProgress({
      percent: 0,
      loadedKb: 0,
      totalKb: 0
    });
    setIsUploading(false);
    setSolutionDescriptionEditorRef(null);
    setSolutionStatus('');
    setTask(null);
    setTabActiveKey('solution-description');
  }

  const handleOpen = () => {
    refreshTask();
  }

  const handleSubmit = async () => {
    const solutionDescription = solutionDescriptionEditorRef.current.getContent();
    
    if (!solutionDescription) {
      toaster.push(<RequestConflictMessage message={'Please provide a solution description before submitting.'} toaster={toaster} />);
      return;
    }

    if (solutionDescription.length > 10000) {
      toaster.push(<RequestConflictMessage message={'Solution description is too long. Please limit it to 10,000 characters.'} toaster={toaster} />);
      return
    }

    try {
      // Call handleSave() and wait for it to finish before proceeding
      await handleSave();

      // Now proceed with the submit logic
      const solution = {
        description: solutionDescription,
        current_status: 'SUBMITTED'
      };
      patchRequest(`/solutions/${task.my_solution.id}/`, solution)
        .then((res) => {
          handleClose();
          toaster.push(<RequestSuccessMessage message={'Congratulations! You successfully submitted your solution.'} toaster={toaster} />)
        })
        .catch((err) => {
          handleClose();
          toaster.push(<RequestErrorMessage error={err} toaster={toaster} />)
        });
    } catch (error) {
      console.error("Error during handleSave or submit:", error);
      toaster.push(<RequestErrorMessage error={'An error occurred while saving or submitting. Please try again.'} toaster={toaster} />);
    }
  };

  async function uploadFiles(solutionId: string) {
    let hasError = false;

    setUploadProgress({
      percent: 0,
      loadedKb: 0,
      totalKb: 0
    });
    setIsUploading(true);

    const filesToUpload = [...fileList];
    let totalSize = 0;
    let totalLoaded = 0;

    filesToUpload.forEach((file) => {
      if (file.status === 'selected') {
        totalSize += file.blobFile.size;
      }
    });

    const uploadFile = async (file: any) => {
      try {
        updateFileStatus(file.fileKey, 'uploading');

        const response = await postRequest(`/solutions/${solutionId}/files/`, {
          file_name: file.name,
          content_type: file.type
        });

        const { id, url, content_type: contentType } = response.data;

        let previousLoaded = 0;

        const config = {
          onUploadProgress: (progressEvent: any) => {
            const loadedForThisFile = progressEvent.loaded - previousLoaded;
            previousLoaded = progressEvent.loaded;

            totalLoaded += loadedForThisFile;

            const percentCompleted = Math.round((totalLoaded * 90) / totalSize);
            setUploadProgress({
              percent: percentCompleted,
              loadedKb: Math.round(totalLoaded / 1024),
              totalKb: Math.round(totalSize / 1024)
            });
          }
        };

        await putFileRequest(url, file.blobFile, config.onUploadProgress, { 'Content-Type': contentType });
        updateFileStatus(file.fileKey, 'processing');

        await putRequest(`/solutions/${solutionId}/files/${id}/`, {
          task_id: taskId,
          status: 'UPLOADED'
        });

        updateFileStatus(file.fileKey, 'uploaded');
      } catch (error: any) {
        updateFileStatus(file.fileKey, 'error');
        toaster.push(<RequestErrorMessage error={error || 'An error occurred during the upload process.'} toaster={toaster} />);
        hasError = true;
      }
    };

    for (const file of filesToUpload) {
      if (file.status === 'selected') {
        await uploadFile(file);
      }
    }

    setUploadProgress({
      percent: 100,
      loadedKb: Math.round(totalSize / 1024),
      totalKb: Math.round(totalSize / 1024)
    });

    setIsUploading(false);

    if (hasError) {
      throw new Error('One of the files failed to upload.');
    }
  }

  const updateFileStatus = (fileKey: string, status: string) => {
    setFileList((prevFileList: any) =>
      prevFileList.map((file: any) =>
        file.fileKey === fileKey ? { ...file, status } : file
      )
    );
  };

  const handleSave = async () => {
    const hasFiles = fileList.length > 0;

    const solution = {
      description: solutionDescriptionEditorRef.current.getContent(),
    };

    try {
      // Make the patch request and wait for it to complete
      const res = await patchRequest(`/solutions/${task.my_solution.id}/`, solution);

      if (res.status === 200) {
        // If there are files, wait for the file upload to complete before returning
        if (hasFiles) {
          try {
            await uploadFiles(task.my_solution.id);  // Wait for file upload to complete
            toaster.push(<RequestSuccessMessage message={'Solution successfully updated with files.'} toaster={toaster} />);
            refreshTask();
          } catch (uploadError) {
            console.error(uploadError);
            toaster.push(<RequestErrorMessage error={'An error occurred while uploading task files. Please try again.'} toaster={toaster} />);
          }
        } else {
          // No files to upload, just show the success message
          toaster.push(<RequestSuccessMessage message={'Solution successfully updated.'} toaster={toaster} />);
          refreshTask();
        }
      } else {
        toaster.push(<RequestErrorMessage error={'An error occurred while updating solutions. Please try again.'} toaster={toaster} />);
      }
    } catch (err) {
      // Handle any errors from the patch request
      toaster.push(<RequestErrorMessage error={err} toaster={toaster} />);
    }
  };

  const handleFileChange = (updatedFileList: any) => {
    setDummyFileList([]);
    console.log("handleFileChange", updatedFileList);
    const filteredFiles = updatedFileList.filter((file: any) => {
      if (file.blobFile.size > maxUploadFileSize) {
        toaster.push(<RequestConflictMessage message={`File "${file.blobFile.name}" exceeds the ${maxUploadFileSize}MB size limit.`} toaster={toaster} />);
        return false;
      }
      return true
    });

    const uploaded_file_count = task?.my_solution?.attached_files.length || 0;
    const totalFiles = fileList.length + filteredFiles.length + uploaded_file_count;
    if (totalFiles > maxFilesPermittedForUpload) {
      toaster.push(<RequestConflictMessage message={`You can upload a maximum of ${maxFilesPermittedForUpload} files per task.`} toaster={toaster} />);
      return;
    }

    // Update fileList with both the metadata and actual file content
    const updatedFilesWithStatus = filteredFiles.map((file: any) => ({
      ...file,
      status: 'selected',
      blobFile: file.blobFile // Ensure this contains the actual file content
    }));

    // Prevent duplicate files from being added
    setFileList((prevFileList: any) => {
      const newFiles = updatedFilesWithStatus.filter(
        (file: any) => !prevFileList.some((f: any) => f.fileKey === file.fileKey)
      );
      return [...prevFileList, ...newFiles];
    });
  };

  function removeFile(fileKey: string) {
    setFileList((prevFileList: any) =>
      prevFileList.filter((file: any) => file.fileKey !== fileKey)
    );
  }

  function onDeleteFile(fileId: string) {
    deleteRequest(`/solutions/${task.my_solution.id}/files/${fileId}/`)
      .then((res) => {
        refreshTask();
      })
      .catch((err) => {
        toaster.push(<RequestErrorMessage error={err} toaster={toaster} />);
      });
  }

  const handleFeedback = () => {
    setTaskFeedbackModalOpen(true);
  };

  const handleFeedbackModalClose = () => {
    setTaskFeedbackModalOpen(false);
    handleClose();
  }

  const handleCancel = () => {
    deleteRequest(`/solutions/${task.my_solution.id}/`)
      .then((res) => {
        handleClose();
        toaster.push(<RequestSuccessMessage message={'You successfully canceled solving the task.'} toaster={toaster} />)
      })
      .catch((err) => {
        toaster.push(<RequestErrorMessage error={err} toaster={toaster} />)
      }
      );
  }

  const handleTaskMessagingClose = () => {
    setTaskMessagingModalOpen(false);
    refreshTask();
  }

  return (
    <>
      <TaskFeedbackModal open={taskFeedbackModalOpen} task={task} close={handleFeedbackModalClose} />

      <TaskMessaging
        open={taskMessagingModal}
        task={task}
        close={handleTaskMessagingClose}
        role="solver"
      />

      <Modal size="lg" open={open} onClose={handleClose} onOpen={handleOpen}>
        <Modal.Header>
          <Modal.Title>Task Solution ({solutionStatus})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {task ? (
            <>
              <TaskDetails task={task} showDetails={false} showSolutionDetails={false} showNetAmount={true} />

              {solutionStatus !== 'REQUESTED INVITATION' &&
                <Form fluid>
                  <Tabs activeKey={tabActiveKey} onSelect={setTabActiveKey} id="tabs" style={{marginTop: "20px"}}>
                    <Tabs.Tab eventKey="solution-description" title="Solution">
                      <div style={{height: "400px"}}>
                        <Form.Group controlId="solution-description">
                          <Form.HelpText>Describe your solution clearly and concisely"</Form.HelpText>
                          <ZipplyEditor key={task.id + '-solution-description'} content={initialSolutionDescription} setEditorRef={setSolutionDescriptionEditorRef} readOnly={solutionReadOnly} height={350} />
                        </Form.Group>
                      </div>
                    </Tabs.Tab>

                    <Tabs.Tab eventKey="task-description" title="Task Overview">
                      <div style={{height: "400px"}}>
                        <Form.Group controlId="task-description">
                          <ZipplyEditor key={task.id + '-task-description'} content={task.description} readOnly={true} height={350} />
                        </Form.Group>
                      </div>
                    </Tabs.Tab>

                    {task.protected_description &&
                      <Tabs.Tab eventKey="task-protected-description" title="Task Description">
                        <div style={{height: "400px"}}>
                          <Form.Group controlId="task-protected-description">
                            <ZipplyEditor key={task.id + '-task-protected-description'} content={task.protected_description} readOnly={true} height={350} />
                          </Form.Group>
                        </div>
                      </Tabs.Tab>
                    }

                    {task.attached_files && task.attached_files.length > 0 && (
                    <Tabs.Tab eventKey="task-files" title="Task Files">
                      <div style={{height: "400px"}}>
                          <ul>
                          {task.attached_files.map((file: any, index: number) => (
                            <li><FilePreview file={file}/></li>
                          ))
                        }
                        </ul>
                      </div>
                    </Tabs.Tab>
                    )}

                    <Tabs.Tab eventKey="solution-files" title="Solution Files">
                      <div style={{height: "400px"}}>
                      <Form.Group controlId="solution-files">
                        <Form.HelpText>Upload any relevant files below</Form.HelpText>
                        <div style={{ fontSize: "12px" }}>
                          {task?.my_solution?.attached_files && task?.my_solution?.attached_files.length > 0 && (
                            <ul>
                              {task?.my_solution.attached_files.map((file: any, index: number) => (
                                <li key={file.id}><FilePreview file={file} /> {solutionStatus === 'INVITED' && <IconButton icon={<RemoveIcon />} appearance="subtle" onClick={() => onDeleteFile(file.id)} />}</li>
                              ))
                              }
                            </ul>)
                          }
                        </div>
                        {solutionStatus === 'INVITED' && (<>
                          <FileUploadList
                            fileList={fileList}
                            onRemove={removeFile}
                            isUploading={isUploading}
                          />
                          <Form.Control
                            name="file"
                            accepter={FileUploader}
                            autoUpload={false}
                            draggable
                            fileListVisible={false}
                            fileList={dummyFileList}
                            multiple={true}
                            onChange={handleFileChange}
                            disabled={isUploading}
                          /></>)}
                      </Form.Group>
                      </div>
                    </Tabs.Tab>
                  </Tabs>
                </Form>}
            </>
          ) : (
            <Loader size="md" content="Fetching task details..." />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Grid fluid style={{ marginTop: "5px" }}>
            <Row>
              <Col xs={2} style={{ textAlign: "left" }}>
                {task?.can_post_message && (<>
                  {task?.unread_message_count > 0 ?
                    <Badge>
                      <MessageIcon style={{ fontSize: "2em", cursor: "pointer"}} onClick={() => setTaskMessagingModalOpen(true)} />
                    </Badge>
                    :
                    <MessageIcon style={{ fontSize: "2em", cursor: "pointer"}} onClick={() => setTaskMessagingModalOpen(true)} />
                  }
                </>)}
              </Col>
              <Col xs={12} style={{ textAlign: "left" }}>
                {isUploading &&
                  <div>
                    <Progress.Line
                      percent={uploadProgress.percent}
                      strokeColor="#4caf50"
                      showInfo={true}
                    />
                    <div style={{ marginLeft: "12px", fontSize: "10px" }}>
                      {uploadProgress.loadedKb} KB / {uploadProgress.totalKb} KB uploaded
                    </div>
                  </div>
                }
                {isUploading &&
                  <Loader size="sm" content={`Processing files...`} />
                }
              </Col>
              <Col xs={10} style={{ textAlign: "right" }}>
                {task?.my_solution?.can_provide_feedback && (
                  <Button appearance="primary" onClick={handleFeedback} disabled={task?.my_solution?.current_status !== 'ACCEPTED'}>
                    Provide Feedback
                  </Button>
                )}
                {solutionStatus === 'INVITED' && (<>
                  <Button appearance="primary" onClick={handleSave}>
                    Save Changes
                  </Button>
                  <Button appearance="primary" onClick={handleSubmit} disabled={task?.current_status !== "POSTED"}>
                    Submit Solution
                  </Button>
                </>)}
                {task?.my_solution?.can_cancel && (
                  <Button appearance="primary" color="red" onClick={handleCancel} >
                    Withdraw
                  </Button>
                )}
              </Col>
            </Row>
          </Grid>
        </Modal.Footer>
      </Modal>
    </>
  );
}
