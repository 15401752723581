import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import * as Yup from "yup";
import { Modal, useToaster, Button, Form } from 'rsuite';

import { sendGAEvent } from 'components/tracker/GoogleAnalyticsTracker';

import { useSelector } from "react-redux";
import {RootState} from "store";
import { displayFee } from 'utils/utils';

import { postRequest } from "utils/axios";
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';

interface Props {
    open: boolean;
    close: () => void;
}

const ReferralModal = ({ open, close }: Props) => {
    const configuration: any = useSelector((state: RootState) => state.configuration);

    const toaster = useToaster();
    const [email, setEmail] = useState('');

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Please provide a valid email address.")
            .required("Please provide a valid email address."),
    });

    useEffect(() => {
        sendGAEvent("Referral", "View", "Referral Page");
    }, []);

    const handleClose = () => {
        close();
    };

    const handleReferralInvitation = () => {
        validationSchema.isValid({ email }).catch((error) => {
            toaster.push(<RequestErrorMessage error="Please provide a valid email address." toaster={toaster} />);
        });
        postRequest(`/referrals/`, { email }).then((res) => {
            toaster.push(<RequestSuccessMessage message={'Thank you for sharing Zipply! Your referral invitation has been successfully sent. We appreciate your effort in growing our community!'} toaster={toaster} />)
            close();
        }).catch((error) => {
            toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
        })
    };

    return (
        <Modal
            size="xs"
            open={open}
            onClose={handleClose}>
            <Modal.Header>
                <Modal.Title>Zipply Referral Program</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "13px" }}>
                <p>Sharing Zipply with friends is a win-win! For every friend you refer, you’ll earn {displayFee(configuration.fees?.referral)} of their reward from the first task they solve or post successfully. Help them discover a fast, easy way to tackle tasks, and let’s grow the Zipply community together!</p>

                <Form fluid>
                    <Form.Group>
                        <Form.Control
                            name="email"
                            placeholder="Enter your friend's email address"
                            onChange={setEmail}
                        />
                    </Form.Group>
                    <div style={{textAlign: "center"}}>
                        <Button style={{ width: '100%' }} onClick={handleReferralInvitation} color="green" appearance="primary">Invite Friend</Button>
                    </div>
                </Form>

                <div style={{fontSize: "10px", marginTop: "15px"}}>
                <span style={{fontWeight: "800"}}>Referral Earnings Disclaimer:</span>
                <p style={{marginTop: "5px"}}>Your earnings from referring a friend will be available once they successfully complete their first task as either a tasker or solver. These earnings are deferred for 30 days after the task is completed and will be paid out on the 28th of each month. A verified payout account through Stripe Connect is required to access your funds. See the <Link to="/terms-of-service">Terms of Service</Link> for more details on referral programs.</p> 
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ReferralModal;
