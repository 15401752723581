import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Grid, Row } from 'rsuite';

import { useSelector } from "react-redux";
import {RootState} from "store";
import { displayFee } from 'utils/utils';

import PublicHeader from 'components/workspace/PublicHeader';
import PublicFooter from 'components/workspace/PublicFooter';

interface Props {
    signIn?: boolean
    signUp?: boolean
    resetPassword?: boolean
}

export default function PaymentTOS({ signIn, signUp, resetPassword }: Props) {
    const configuration: any = useSelector((state: RootState) => state.configuration);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <PublicHeader
                showSearch={false}
                signIn={signIn}
                signUp={signUp}
                resetPassword={resetPassword}
            />
            <Grid fluid>
                <Row style={{ margin: '20px' }}>
                    <h1> Payment Terms</h1>
                    <p>Last Updated: November 11, 2024</p>

                    <section>
                        <p>These "<strong>Payment Terms</strong>" govern all payments made or received through Zipply (the “<strong>Site</strong>”), operated by Zipply.</p>

                        <p>Zipply currently processes all payments through Stripe. By using the Site to make or receive payments, you acknowledge that Stripe’s terms and conditions apply to these transactions.</p>

                        <p>Please review these <strong>Payment Terms</strong> carefully before making or receiving payments on the Site. By proceeding with payments, you agree, either on your own behalf or on behalf of your employer or another entity (if applicable), to comply with these <strong>Payment Terms</strong>. These terms are supplementary to Zipply’s General  <Link to="/terms-of-service">Terms of Service</Link> and other applicable policies (collectively referred to as the “<strong>Terms of Service</strong>”).</p>
                    </section>

                    <section>
                        <h2>Key Terms</h2>

                        <p><strong>Taskers</strong> are users who post tasks and offer rewards for solutions.</p>
                        <p><strong>Solvers</strong> are users who complete tasks and submit solutions in exchange for rewards.</p>
                        <p><strong>Rewards</strong> mean the monetary compensation offered by taskers for completed tasks.</p>
                        <p><strong>Fees</strong> are charges associated with the services provided on Zipply.</p>
                        <p><strong>User Group</strong> Refers to a set of accounts associated with the same individual or family, where the total transactions across all accounts within the group are subject to the same limits.</p>
                    </section>

                    <section>
                        <h2>Overview of Payment Terms</h2>
                        <p>Zipply facilitates payments between Taskers and Solvers for completed tasks. Taskers offer rewards for tasks, and Solvers receive these rewards upon successful completion of the task. Zipply charges a service fee on each task reward, which is deducted before the Solver receives their payment. Our fee structure is designed to ensure transparency and simplicity for both Taskers and Solvers. The following sections outline the specific processes, rights, and obligations for both parties.</p>
                    </section>

                    <section>
                        <h2>Service Fees and Payment Structure</h2>
                        <ol>
                            <li>
                                <strong>Reward Pricing:</strong> Taskers set a reward price for each task. This reward is paid to Solvers upon solution acceptance, minus a service fee retained by Zipply.
                            </li>
                            <li><strong>Payment Authorization:</strong> When Taskers post tasks, a payment authorization for the reward amount is placed on their payment method. The payment is charged when the first solution is submitted.
                            </li>
                            <li>
                                <strong>Service Fee:</strong>
                                <ul>
                                    <li>Zipply charges a {displayFee(configuration.fees?.task)} service fee on each task reward.</li>
                                    <li>A {displayFee(configuration.fees?.taskRefund)} convenience fee applies for cancellations after solutions have been submitted.</li>
                                    <li>Solvers see the net reward after service fees; Taskers see the full reward amount they set.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Fee Adjustments:</strong> Zipply may revise service fees to support platform improvements. Users will be notified in advance of any changes.
                            </li>
                            <li>
                                <strong>Promotions and Discounts:</strong> Zipply may offer temporary fee reductions or incentives. Such promotions will not affect the standard fee structure unless explicitly stated.
                            </li>
                            <li>
                                <strong>Task Cancellations:</strong>
                                <ul>
                                    <li>Tasks can be canceled at no cost if no solutions have been submitted.</li>
                                    <li>Cancellations after submission are subject to review and a convenience fee.</li>
                                    <li>Solvers may withdraw from a task before submitting a solution. However, once a solution is submitted, the task is considered active, and the Solver is committed to completing it.</li>
                                    <li>Zipply may cancel tasks at its discretion if it believes either the Tasker or Solver has violated the platform’s rules or terms.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Payment to Solvers:</strong>
                                <ul>
                                    <li>Payments to Solvers are processed upon solution acceptance.</li>
                                    <li>Payments are deferred for 30 days and released on the 28th of the following month.</li>
                                    <li>Solvers must maintain a verified payout account with Stripe Connect.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Payout Process:</strong> Solvers are responsible for ensuring accurate account details. Deferred payments are automatically processed during the next payout cycle.
                            </li>
                            <li>
                                <strong>Overdue Tasks:</strong> Tasks must be completed by the deadline set by the Tasker. If a task is not completed by the deadline, it will automatically be marked as "In Review." Taskers have the option to extend the deadline of an "In Review" task if they find it necessary and can communicate this change to the Solvers. Once a task is in review, Taskers must review and accept a solution within 7 days after the due date. If they do not take action within this timeframe, Zipply will attempt to select a reasonable solution from the submitted entries or process a refund minus any applicable convenience fees.
                            </li>
                            <li>
                                <strong>Refunds:</strong> Refunds are not available for tasks once a solution has been submitted. Taskers can always request a cancellation after a solution has been submitted, which will be subject to review by Zipply. If a cancellation request is approved, a refund will be issued, but it will be subject to a convenience fee. For overdue tasks where a solution has not been accepted within 7 days after the due date, Zipply will either select a reasonable solver solution or process a refund, both of which will incur a convenience fee based on the service fee structure.
                            </li>
                            <li>
                                <strong>Disputes:</strong> While we aim to provide a smooth payment process, disputes may arise. Currently, we anticipate handling disputes primarily related to chargebacks. Should additional disputes occur, we will review each case on an individual basis. Disputes must be raised within 14 days of task completion.
                            </li>
                            <li>
                                <strong>Chargebacks:</strong>
                                <ul>
                                    <li><strong>Chargeback Initiation:</strong> If a chargeback is initiated by the Tasker or Solver’s bank, Zipply will work with the user’s bank to resolve the issue. Any chargeback fees or penalties will be the responsibility of the user initiating the chargeback.</li>
                                    <li><strong>Liability for Chargebacks:</strong> Zipply reserves the right to charge users for any fees or penalties incurred from chargebacks. Repeated chargebacks may result in the suspension or termination of the user’s account.</li>
                                </ul>
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2>Transaction and Account Limitations</h2>
                        <ul>
                            <li>
                                <strong>Task Limits for Taskers:</strong> The daily task limit refers to the maximum total amount that can be transacted in a single day across all accounts within the same <strong>user group</strong>, with a limit of $100 per day. These limits apply collectively to the total account balance of the <strong>user group</strong>, so all accounts combined must adhere to the daily thresholds. Furthermore, there is a maximum of 10 transactions allowed per day across all accounts within the <strong>user group</strong>.
                            </li>
                            <li>
                                <strong>Shared Payment Source:</strong> If multiple users link their accounts to the same payment method, the transaction limits will apply to all activities associated with that payment method. This ensures that the total sum of transactions does not exceed any limits, regardless of which account initiates the transaction.
                            </li>
                            <li>
                                <strong>Notification of Limit Exceedance:</strong> If you attempt to exceed any limits for you will be notified that the transaction has been rejected. You may still be able to submit tasks or receive payments within the available limit for that day, subject to the shared usage across all accounts linked to the <strong>user group</strong>.
                            </li>
                            <li>
                                <strong>Enforcement:</strong> We reserve the right to enforce these transaction limits and take action if they are consistently exceeded or violated, including disabling payment methods or accounts associated with excessive transactions.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2>Taxes</h2>
                        <h3>Tax Responsibility</h3>
                        <p>Users are responsible for any taxes, fees, or duties arising from their use of Zipply, including income taxes, value-added tax (VAT), or sales tax. Taskers and Solvers are responsible for reporting and paying any taxes applicable to the payments they make or receive.</p>

                        <h3>Tax Reporting</h3>
                        <p>Zipply may issue tax reports as required by law for certain earnings, including 1099 forms for US-based users. Taskers and Solvers are responsible for maintaining their own tax records.</p>
                    </section>

                    <section>
                        <h2>Revisions and Modifications</h2>
                        <h3>Revision Requests</h3>
                        <p>Taskers may request revisions within the agreed-upon scope of the task. Revisions outside the original requirements are not permitted. Solvers can offer a specified number of revisions based on their task agreement.</p>

                        <h3>Unauthorized Requests</h3>
                        <p>Using the revision process to request additional services beyond the task’s scope is prohibited. Zipply reserves the right to address misuse of the revision process.</p>
                    </section>

                    <section>
                        <h2>Changes to Payment Terms</h2>
                        <p>Zipply may update these Payment Terms to reflect changes in legal requirements, platform features, or other circumstances. Updates will be posted here, and continued use of Zipply constitutes acceptance of the revised terms.</p>
                    </section>

                    <section>
                        <h2>Disclaimer of Warranties</h2>
                        <p>Zipply provides the platform "as is" and makes no warranties, either express or implied, regarding the availability, performance, or suitability of the Site for any particular purpose. Zipply disclaims all warranties of merchantability, fitness for a particular purpose, and non-infringement to the fullest extent allowed by law.</p>
                    </section>

                    <section>
                        <h2>Limitation on Liability</h2>
                        <p>To the fullest extent permitted by law, Zipply's liability is limited to the amount of fees paid by the user in the past 30 days prior to the claim. Zipply is not liable for indirect, incidental, or consequential damages, including but not limited to lost profits, data, or business interruptions, arising from the use of the Site or related services.</p>
                    </section>

                    <section>
                        <h2>Indemnification</h2>
                        <p>Users agree to indemnify and hold Zipply harmless from any claims, losses, damages, liabilities, or expenses arising from their use of the Site, violation of these Payment Terms, or infringement of any third-party rights.</p>
                    </section>

                    <section>
                        <h2>Miscellaneous</h2>
                        <ul>
                            <li><strong>Governing Law:</strong> These Payment Terms are governed by the laws of the jurisdiction in which Zipply operates, without regard to its conflict of law principles.</li>
                            <li><strong>Entire Agreement:</strong> These Payment Terms constitute the entire agreement between the parties regarding payments on the Site and supersede all prior agreements or understandings, whether written or oral.</li>
                            <li><strong>Severability:</strong> If any provision of these Payment Terms is found to be invalid or unenforceable, the remainder will continue in full force and effect.</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Termination</h2>
                        <p>Zipply reserves the right to suspend or terminate any user account for violating these Payment Terms or for any activity that disrupts the operation of the Site. In the event of termination, all outstanding payments will be processed in accordance with the terms above, and any pending transactions will be subject to review.</p>
                    </section>

                    <section>
                        <p>By using Zipply, you agree to these payment terms, which are designed to protect both Taskers and Solvers while fostering a fair and efficient marketplace.</p>
                    </section>

                    <section>
                        <h2>Contact Information</h2>
                        For any questions or concerns regarding these Payment Terms, please contact Zipply Support at legal@zipply.com.
                    </section>
                </Row>
            </Grid>
            <PublicFooter />
        </div>
    );
};
