import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import store, { persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ProtectedRoute from "routes/ProtectedRoute";
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { HelmetProvider } from 'react-helmet-async';

import { Grid, Row } from 'rsuite';

import 'react-chatbot-kit/build/main.css'
import ChatBotNavigate from "components/chatbot/Navigate"

// https://github.com/gilbarbara/react-joyride-demo/blob/main/src/Basic/index.tsx
import Joyride from 'react-joyride';

import { Wallet, TaskBrowser, MyTasks, MySolutions, Profile, Support, AdminAccounts, AdminDeployment, AdminSystem, Dashboard } from "pages/protected";

import ProtectedHeader from "components/workspace/ProtectedHeader"

import { SignIn, SignUp, ResetPassword, GoogleSignIn, Home, CustomRoute, Search } from "pages";
import TOS from "components/help/TOS";
import PaymentTOS from "components/help/PaymentTOS";
import PrivacyPolicy from "components/help/PrivacyPolicy";
import About from "components/help/About";
import PublicFooter from "components/workspace/PublicFooter";


import 'styles/rsuite.css';
import 'styles/styles.css';
import ReactGA from 'react-ga4';
import GoogleAnalyticsTracker from 'components/tracker/GoogleAnalyticsTracker';

const state: any = {
    run: false, // Set this to true for testing the tour
    steps: [
        {
            target: '.welcome',
            title: 'Welcome to Zipply!',
            content: 'This is your dashboard, where you can manage your tasks, solutions. Click to start a tour of the dashboard!',
        },
        {
            target: '.my-active-tasks',
            title: 'Your Active Tasks',
            content: 'Manage all the tasks you’ve posted here. Monitor their progress and review solutions from solvers. Click to view all your active tasks.',
            placement: 'right',
        },
        {
            target: '.my-solutions',
            title: 'Your Active Solutions',
            content: 'View the solutions you’ve submitted. Check the status of your submissions and track their acceptance. Click to see all your solutions.',
            placement: 'left',
        },
        {
            target: '.latest-tasks',
            title: 'Latest Tasks Available',
            content: 'Explore the most recent tasks posted by other users. Use filters or search to find tasks you can solve. Click to view all the latest tasks.',
            placement: 'left',
        },
        {
            target: '.find-tasks',
            title: 'Search Latest Tasks',
            content: 'Browse or search for tasks that suit your skills and interests. This section filters the latest tasks for you!',
            placement: 'bottom',
        },
        {
            target: '.post-task',
            title: 'Post a Task',
            content: 'Need something done? Post your task here by providing details and setting a reward, then let solvers step in to help!',
            placement: 'bottom',
        },
        {
            target: '.totals',
            title: 'Your Financials',
            content: 'Track your total spending and earnings here. This section gives an overview of your Zipply financials. Click to access your wallet.',
            placement: 'bottom',
        },
        {
            target: '.avatar',
            title: 'Manage Your Profile & Wallet',
            content: 'Click on your avatar to manage your profile, wallet, refer a friend, and other settings. You can also sign out from here.',
            placement: 'bottom',
        },
        {
            target: '.topusers',
            title: 'Top Taskers & Solvers',
            content: 'Explore top Taskers & Solvers on Zipply! Click to view their profile, badges, feedback, followers, and activity history.',
            placement: 'right',
        },
    ]
};


export default function App() {
    const [isBodyRefresh, setIsBodyRefresh] = useState(false);
    const [isHeaderRefresh, setIsHeaderRefresh] = useState(false);
    const [{ steps, run }, setState] = useState<any>(state);

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    }

    const startTour = () => {
        setState({ run: true, steps: steps });
    }

    return (
        <HelmetProvider>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <Router>
                    <GoogleAnalyticsTracker />
                    <Routes>
                        <Route path='/home' element={
                            <Home />}
                        />
                        <Route path='/search' element={<Search />} />
                        <Route path='/signin' element={<SignIn />} />
                        <Route path='/signup' element={<SignUp />} />
                        <Route path='/resetpassword' element={<ResetPassword />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/google/signin' element={<GoogleSignIn />} />
                        <Route path='/terms-of-service' element={<TOS />} />
                        <Route path='/payment-terms-of-service' element={<PaymentTOS />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />

                        <Route element={
                            <ProtectedRoute>
                                <ThemeProvider
                                    breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
                                    minBreakpoint="xxs">
                                    <Joyride
                                        continuous
                                        run={run}
                                        scrollToFirstStep
                                        showProgress
                                        showSkipButton
                                        steps={steps}
                                    />
                                    <div className="ro-page-default">
                                        <div className="ro-header">
                                            <ProtectedHeader isHeaderRefresh={isHeaderRefresh} acknowledgeHeaderRefresh={() => setIsHeaderRefresh(false)} refreshBody={() => setIsBodyRefresh(true)} startTour={startTour} />
                                        </div>

                                        {/* top and bottom ids are used to quickly navigate to top and bottom of longer lists */}
                                        <div className="ro-body" id='top'>
                                            <Grid fluid>
                                                <Row>
                                                    <Outlet />
                                                </Row>
                                            </Grid>
                                        </div>

                                        <div className="ro-bottom" id='bottom'>
                                            <PublicFooter />
                                        </div>
                                    </div>
                                </ThemeProvider>
                            </ProtectedRoute>
                        }>

                            <Route path="/" element={
                                <ProtectedRoute>
                                    <Dashboard
                                        isBodyRefresh={isBodyRefresh}
                                        acknowledgeBodyRefresh={() => setIsBodyRefresh(false)}
                                    />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/profile" element={
                                <ProtectedRoute>
                                    <Profile refreshHeader={() => setIsHeaderRefresh(true)}/>
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/wallet" element={
                                <ProtectedRoute>
                                    <Wallet refreshHeader={() => setIsHeaderRefresh(true)}/>
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/tasks" element={
                                <ProtectedRoute>
                                    <TaskBrowser />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/my-solutions" element={
                                <ProtectedRoute>
                                    <MySolutions />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/my-tasks" element={
                                <ProtectedRoute>
                                    <MyTasks isBodyRefresh={isBodyRefresh} acknowledgeBodyRefresh={() => setIsBodyRefresh(false)} />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/support" element={
                                <ProtectedRoute>
                                    <Support />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/navigate/*" element={
                                <ProtectedRoute>
                                    <ChatBotNavigate />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/admin/accounts" element={
                                <ProtectedRoute>
                                    <AdminAccounts />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/admin/system" element={
                                <ProtectedRoute>
                                    <AdminSystem />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/admin/deployments" element={
                                <ProtectedRoute>
                                    <AdminDeployment />
                                </ProtectedRoute>
                            }
                            />
                            <Route />
                        </Route>

                        <Route path='*' element={<CustomRoute />} />
                    </Routes>
                </Router>
            </PersistGate>
        </Provider>
        </HelmetProvider>
    );
}