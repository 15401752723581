import { DatePicker } from 'rsuite';

import addHours from 'date-fns/addHours';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';

const predefinedDueDateRanges: any = [
  /* TODO: Maybe re-introduce these later
    {
      label: '1 Hour',
      value: addHours(new Date(), 1),
      placement: 'left'
    },
    {
      label: '2 Hour',
      value: addHours(new Date(), 1),
      placement: 'left'
    },
    {
      label: '3 Hour',
      value: addHours(new Date(), 1),
      placement: 'left'
    },
    {
      label: '5 Hour',
      value: addHours(new Date(), 1),
      placement: 'left'
    },
    */
    {
      label: 'Tomorrow',
      value: addDays(new Date(), 1),
      placement: 'left'
    },
    {
      label: '2 Days',
      value: addDays(new Date(), 2),
      placement: 'left'
    },
    {
      label: '3 Days',
      value: addDays(new Date(), 3),
      placement: 'left'
    },
    {
      label: '1 Week',
      value: addWeeks(new Date(), 1),
      placement: 'left'
    }
  ];

interface Props {
    dueDate: any;
    setDueDate: (dueDate: any) => void;
  }
  
  export default function DueDatePicker({ dueDate, setDueDate }: Props) {
    // TODO Pull from configuration at some point
    const maxDueDate = addWeeks(new Date(), 1); 
    const minDueDate = addHours(new Date(), 1); 

    function disabledDateRange(date: any) {
      return date <= minDueDate || date > maxDueDate;
    }

    return (
        <DatePicker 
            format="MM/dd/yyyy hh:mm aa" 
            showMeridian
            ranges={predefinedDueDateRanges}
            value={dueDate} 
            onChange={setDueDate} 
            placeholder="Select due date" 
            block 
            shouldDisableDate={disabledDateRange} 

        />
    );
  };
